import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WEBSERVICE_ADDR } from '@app/shared/constants';
import { Agency } from '@app/shared/models/agence';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreLoadApplicationService {

  constructor(private http: HttpClient) {}

  /** Recupere la liste des agences d'une entreprise*/
  getAgences():Observable<Agency[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const options = { headers };
    const url = WEBSERVICE_ADDR + 'commercial/agencies';

    return this.http.get<Agency[]>(url, options);
  }
}

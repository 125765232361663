import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/services/authentication.service';

@Component({
  selector: 'shared-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(authService: AuthenticationService) { }

  ngOnInit(): void {
  }

  logout() {
    //this.authService.logout();
  }

}

import { AfterViewInit, Component, OnInit, ɵConsole } from '@angular/core';
import { ClientService } from '@app/services/client.service';
import {GridDataResult, PageChangeEvent} from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { ClientInPegase } from '@app/shared/models/client-model';
import { CreateCompteClipForm } from '@app/shared/models/create-compte-clip-form-model';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { UserHelper } from '@app/helpers/user.helper';
import { Shepher } from '@app/shared/models/shepher-model';
import { ShepherdsHelper } from '@app/helpers/shepherds.helper';
import { ShepherdInternServiceService } from '@app/services/shepherd-intern-service.service';

@Component({
  selector: 'app-add-new-client',
  templateUrl: './add-new-client.component.html',
  styleUrls: ['./add-new-client.component.css']
})
export class AddNewClientComponent implements OnInit, AfterViewInit {
  public gridViewContact: GridDataResult;
  public pageSizeContact = 5;
  public contacts: any[];
  public gridView: GridDataResult;
  public pageSize = 100;
  public skip = 0;
  items: any[] = [];
  public coge: string;
  public sort: SortDescriptor[] = [{ field: 'nom', dir: 'asc' }];
  seeTable: boolean = false;
  seeForm: boolean = false;
  client: ClientInPegase;
  contactHaveAccesCompteClip: string;
  newClientForm: FormGroup;
  stepsArray: Shepher[];
  public loading: boolean;

  constructor(
    private clientService: ClientService,
    private shepherdInternService: ShepherdInternServiceService) { }

  ngOnInit(): void {
    this.coge = UserHelper.getUser().coge;
  }

  ngAfterViewInit(): void {
    ShepherdsHelper.setCurrentComponent(this);
    this.shepherdInternService.removeStep();
    if (ShepherdsHelper.getState() === true) {
      this.loadHelpInline();
    }
  }

  /**
   * Permet d'afficher laide en ligne
   */
  loadHelpInline() {
    this.stepsArray = [];
    this.stepsArray.push(new Shepher('clipRechercheInPegase', '.shepherd-button-021'
      , ['Recherchez un client dans PEGASE par son code ou par raison sociale.'], false, true));
    this.shepherdInternService.createTourOfSteps(this.stepsArray);
  }

  public selectionChange(e) {
    if (e.selectedRows[0]) {
      this.client = e.selectedRows[0].dataItem;
      this.newClientForm = new FormGroup({
        contact: new FormControl('', Validators.required),
        email: new FormControl('', [Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")]),
        is_signataire: new FormControl(true, Validators.required),
        is_invoicing: new FormControl(false, Validators.required)
      });

      if (!this.seeForm) {
        this.seeForm = true;
        if (ShepherdsHelper.getState() === true) {
          //fermer un pop ouvert si un popup est ouvert avant d'ouvrir un nouveau
          this.shepherdInternService.hideStep();
          this.stepsArray.push(new Shepher('clipCreateForm', '.shepherd-button-023',
            ["Ce formulaire vous permet de créer un accès client. Vous pouvez préremplir le champs « Contact » et « Email »" +
             " en selectionnant un contact souhaité dans la table de contact ci dessous. Le champ « Signature » par défaut est à « Oui »"], true, false,true));
          this.shepherdInternService.createSteps(this.stepsArray);
          this.shepherdInternService.getShepherdService().show('clipCreateForm');
        }
      }
      this.clientService.getContactClient(this.client.cetatu)
        .subscribe((res: any[]) => {

          if(res ){
            this.contacts = res;
            this.pageSizeContact = res.length;
            this.gridViewContact = {
              data: orderBy(this.contacts, this.sort),
              total: res.length
            }
          }
        });
    } else {
      this.seeForm = false;
    }
  }

  public selectionChangeContact(e) {
    if (e.selectedRows[0]) {
      this.contactHaveAccesCompteClip = e.selectedRows[0].dataItem.access_cetatu;
      this.newClientForm.setValue({
        contact: e.selectedRows[0].dataItem.nom ? e.selectedRows[0].dataItem.nom : "",
        email: e.selectedRows[0].dataItem.email ? e.selectedRows[0].dataItem.email : "",
        is_signataire: true,
        is_invoicing: false
      });
    } else {
      this.newClientForm.setValue({
        contact: '',
        email: '',
        is_signataire: true,
        is_invoicing: false
      });
    }
  }

 public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems();
  }

  public loadItems(): void{
    this.gridView = {
      data: orderBy(this.items.slice(this.skip, this.skip + this.pageSize), this.sort),
      total: this.items.length
    };
  }

  onSearch(filter) {
    if (filter.trim()) {
      this.loading = true;
      this.clientService.searchClientsInPegase(filter.trim())
        .subscribe((res: ClientInPegase[]) => {
          if(res) {
            this.pageSize = res.length;
            this.gridView = {
              data: orderBy(res, this.sort),
              total: res.length
            }
              this.loading = false;
          }
          if (!this.seeTable) {
            this.seeTable = true;

            //cette partie concerne l'aide en ligne
            if (ShepherdsHelper.getState() === true) {
              //fermer un pop ouvert si un popup est ouvert avant d'ouvrir un nouveau
              this.shepherdInternService.hideStep();
              this.stepsArray.push(new Shepher('clipCreateTable', '.shepherd-button-022',
                ["Tableau des clients dont le nom contient l'expression rechercher. Selectioner un client en cliquant sur la case à cocher afin d'ouvrir le formulaire de création d'accès."], true, true));
              this.shepherdInternService.createSteps(this.stepsArray);
              this.shepherdInternService.getShepherdService().show('clipCreateTable');
            }
          }
        }
        );
    } else {
      this.seeForm = false;
      this.seeTable = false
    }
  }

  createCompteClip() {
    if (this.newClientForm.valid && (!this.contactHaveAccesCompteClip)) {
      this.clientService.createCompteClip(new CreateCompteClipForm( UserHelper.getUser().coge, this.client.cetatu, this.newClientForm.value.email,
        this.newClientForm.value.contact, this.newClientForm.value.is_signataire, this.newClientForm.value.is_invoicing), this);
        this.seeForm = false
    }
  }

  cancel() {
    this.seeForm = false;
  }
}

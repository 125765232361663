export class CreateCompteClipForm {
    coge: string;
    cetatu: string;
    email: string;
    contact: string;
    is_signataire: boolean;
    is_invoicing: boolean;

    constructor(coge?: string, cetatu?: string, email?: string, contact?: string, is_signataire?: boolean, is_invoicing?: boolean) {
        this.coge = coge;
        this.cetatu = cetatu;
        this.email = email;
        this.contact =  contact;
        this.is_signataire = is_signataire;
        this.is_invoicing = is_invoicing;
    }
}

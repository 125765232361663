import { Message } from '@shared/models/message-model';
import { Component, OnInit } from '@angular/core';
import { DataStateChangeEvent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { finalize } from 'rxjs/operators';
import { InterimaireService } from '@app/services/interimaire.service';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { ShepherdInternServiceService } from '@app/services/shepherd-intern-service.service';
import { ShepherdsHelper } from '@app/helpers/shepherds.helper';
import { Shepher } from '@app/shared/models/shepher-model';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-message-isi-history',
  templateUrl: './message-isi-history.component.html',
  styleUrls: ['./message-isi-history.component.css']
})
export class MessageIsiHistoryComponent implements OnInit {

  public gridView: GridDataResult;
  private totalLength: number;
  private items: Message[] = [];
  filter: string = "";
  page: number;
  public state: DataSourceRequestState = {
    skip: 0,
    take: 60,
  };
  stepsArray: Shepher[] = [];
  public loading: boolean;

  constructor(
    private interimaireService: InterimaireService,
    private shepherdInternService: ShepherdInternServiceService) { }

  ngOnInit(): void {
    this.page = 1;
    this.loading = true;
    this.getMails();
  }

  getMails() {
    this.interimaireService.getMails(this.page, this.filter)
      .pipe(finalize(() => {
        this.loadItems();
      }))
      .subscribe(response => {
        this.items = [];
        let body = response.body['hydra:member'];
        body.forEach(element => {
          let recepteurs: any[] = [];
          element['recipients'].forEach(recepteur => {
            recepteurs.push(recepteur['email']);

          });
          let etat = element['sent'] ? "Message envoyé" : "Message non envoyé";
          this.items.push(new Message(
            element['sender'],
            recepteurs,
            element['subject'],
            etat,
            element['errorMessage'],
            formatDate(new Date(element['createdAt']), 'dd/MM/yyyy HH:mm:ss', 'en'),
            formatDate(new Date(element['updatedAt']), 'dd/MM/yyyy HH:mm:ss', 'en')));
        });

        this.totalLength = response.body['hydra:totalItems'];
      });
  }
  ngAfterViewInit(): void {
    ShepherdsHelper.setCurrentComponent(this);
    this.shepherdInternService.removeStep();
    if (ShepherdsHelper.getState() === true) {
      this.loadHelpInline();
    }
  }
  loadHelpInline() {
    this.stepsArray = [];
    // créeation de l'array des steps
    this.stepsArray.push(new Shepher('chercherUnMessageParEmail', '.shepherd-button-141'
    , ["Rechercher un message par l'adresse email du destinataire."], false, true));
    this.stepsArray.push(new Shepher('histoMessageIsi', '.shepherd-button-142'
      , ["L'hitorique des activations d'accès des comptes interimaires."], true, false, true));

    //ajouter les steps dans  ShepherdHelper
    this.shepherdInternService.createTourOfSteps(this.stepsArray);

  }

  private loadItems(): void {
    this.gridView = {
      data: this.items,
      total: this.totalLength
    };
    if(this.gridView.data.length >0) {
      this.loading = false;
    }
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
  }

  public pageChange(event: PageChangeEvent): void {
    this.page = (event.skip + this.state.take) / this.state.take;
    this.getMails();
  }

  public onFilter(inputValue: string): void {
    this.page = (this.state.skip + this.state.take) / this.state.take;
    this.filter = inputValue;
    this.getMails();
  }

}


export class MessageNotification {
  id: number;
  coge: string;
  code: string;
  nom: string;
  type: string;
  titre: string;
  date: string;
  message: string;
  dateAction: string;
  dateFin: string;
  constructor(id: number, coge: string, code: string, type: string, nom: string, titre: string, date: string, message: string,
              dateAction: string, dateFin: string) {
    this.id = id;
    this.coge = coge;
    this.code = code;
    this.nom = nom;
    this.type = type;
    this.titre = titre;
    this.date = date;
    this.message = message;
    this.dateAction = dateAction;
    this.dateFin = dateFin;
  }
}

<div class="mainBox" fxLayout="column" flexfill>
  <div class="searchBoxc">
    <!--searchBox-->
    <div class="searchDiv" fxLayout="row wrap" flexfill>
    <label [for]="'input'" class="k-labeltbox">Rechercher un accès client :</label>
    <input class="k-textbox shepherd-button-011" (input)="onSearch($event.target.value)"
               placeholder="Effectuez la recherche par client ou contact"/>
    </div>
    <!--DematBox-->
    <mat-card class="marginCart" *ngIf="seeFormDemat === true">
      <form [formGroup]="dematForm" (ngSubmit)="demat()">
        <span class="title">Dématérialiser le client : </span> <span>{{rscli}}</span>
        <div class="containDiv" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column">
          <div class="containLeftDiv" fxLayout="row" fxLayoutGap="20px">
            <mat-radio-group formControlName="statut_demat">
              <mat-radio-button value="O"> Oui</mat-radio-button>
              <mat-radio-button value="N"> Non</mat-radio-button>
              <mat-radio-button value="F"> Démat et édition</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="containRigthDiv" fxLayout="row" fxLayoutAlign="end start" fxLayoutAlign.xs="center start"
               fxLayoutAlign.sm="center start" fxLayoutGap="20px">
            <button mat-button type="submit" [disabled]="!dematForm.valid">Valider</button>
            <button mat-button (click)="cancelDemat()">Annuler</button>
          </div>
        </div>
      </form>
    </mat-card>
    <!--updateBox-->
    <mat-card class="marginCart" *ngIf="seeFormUpdate == true">
      <form [formGroup]="updateForm" (ngSubmit)="update()" fxLayout="column" fxLayoutAlign.xs="start center"
            fxLayoutAlign.sm="start center">
        <span class="title"> Mettre à jour le contact : {{clientContact}}</span>
        <div class="containDiv" fxLayout="row" fxLayoutAlign="start start" fxLayout.xs="column" fxLayout.sm="column"
             fxLayoutAlign.xs="start center" fxLayoutAlign.sm="start center">
          <div class="containLeftDiv" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="row"
               fxLayoutAlign.xs="start center" fxLayoutAlign.sm="start center" fxLayoutGap="20px">
            <mat-form-field class="mat-form-field-width" appearance="fill" *ngIf="updateLogin">
              <mat-label>Identifiant</mat-label>
              <input matInput name="login" formControlName="login" #login required>
              <mat-error>Champ obligatoire</mat-error>
            </mat-form-field>
            <mat-form-field class="mat-form-field-width" appearance="fill" *ngIf="updateEmail">
              <mat-label>Email</mat-label>
              <input matInput name="email" formControlName="email" #email required
                     pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
              <mat-error *ngIf="updateForm.controls.email.hasError('required')">Champ obligatoire</mat-error>
              <mat-error *ngIf="updateForm.controls.email.hasError('pattern')">Email invalide</mat-error>
            </mat-form-field>
            <mat-radio-group fxLoyaout="row" fxLayoutGap="5px" formControlName="is_invoicing">
              <mat-label class="label-with-100">Facturation :</mat-label>
              <mat-radio-button [value]=true name="true">Oui</mat-radio-button>
              <mat-radio-button [value]=false name="false">Non</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="containRigthDiv" fxLayout="row" fxLayoutAlign="end start" fxLayoutAlign.xs="center start"
               fxLayoutAlign.sm="center start" fxLayoutGap="20px">
            <button mat-button type="submit" class="valider">Valider</button>
            <button mat-button (click)="cancelUpdate()"> Annuler</button>
          </div>
        </div>
      </form>
    </mat-card>
  </div>
  <div class="loader" *ngIf="loading === true"></div>
  <div class="tableContentBoxc">
    <kendo-grid [kendoGridBinding]="gridView"
                [groupable]="true"
                [group]="groups"
                [filterable]="true"
                [pageSize]="pageSize"
                [skip]="skip"
                [resizable]="true"
                [pageable]="true"
                [sortable]="{ allowUnsort: allowUnsort,mode: 'multiple'}"
                [selectable]="{ checkboxOnly: false, mode: 'simple' }"
                [sort]="sort"
                (groupChange)="groupChange($event)"
                (sortChange)="sortChange($event)"
                (pageChange)="pageChange($event)"
                [style.height]="'100%'"
                [rowClass]="rowCallback">
      <kendo-grid-command-column title="Action" width="150" [headerClass]="'tableHeader'" [resizable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
          <!--Modifier client-->
          <button mat-icon-button aria-label="update" class='violet-icon' matTooltip="Mettre à jour"
                  (click)="openFormUpdate(dataItem)">
            <mat-icon>edit</mat-icon>
          </button>
          <!--Renvoyer le mail d'activation-->
          <button mat-icon-button aria-label="renvoieMailActivation" matTooltip="Renvoyer l'émail d'activation"
                  (click)="onRenvoiMailActivationt(dataItem)"
                  [disabled]="(dataItem.is_actif==='Actif') || (dataItem.is_actif==='Inactif' && dataItem.code_legalyspace)">
            <mat-icon>rotate_left</mat-icon>
          </button>
          <!-- Supprimer-->
          <button mat-icon-button aria-label="delete" class='red-icon' matTooltip="Supprimer" color="primary"
                  (click)="onDelete(dataItem)"
                  [disabled]="(dataItem.is_actif==='Actif') || (dataItem.is_actif==='Inactif' && dataItem.code_legalyspace)">
            <mat-icon> delete</mat-icon>
          </button>
          <button mat-icon-button aria-label="Ouvrir ce compte CLIP" class='vert-icon'  matTooltip="Ouvrir ce compte CLIP en mode admninistrateur" color="primary"
                  (click)="onOpenUserCompteInAdminMode(dataItem)"
                  [disabled]="(dataItem.is_actif==='Inactif')">
            <mat-icon>settings</mat-icon>
          </button>

        </ng-template>
      </kendo-grid-command-column>
      <kendo-grid-column title="" width="50" [headerClass]="'tableHeader'">
        <ng-template kendoGridCellTemplate let-dataItem>

          <img *ngIf="dataItem.is_signataire === 0" src="assets/images/svg/visibility_black_24dp.svg"
               class="responsive logo imageSignature"
               alt="icon signature electronic" matTooltip="Pas de droit à la signature">
          <img *ngIf="dataItem.is_signataire === 1" src="assets/images/signature1.png" class="logo imageSignature"
               alt="icon signature electronic" matTooltip="Droit à la signature">
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="" width="50" [headerClass]="'tableHeader'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <img *ngIf="dataItem.is_invoicing === true" src="assets/images/invoicing.png" class="logo invoice"
               alt="icon invoice" matTooltip="Contact Facturation">
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column class="shepherd-button-012" field="rscli" title="Client" [filterable]="false" width="250"
                         [headerClass]="'tableHeader'" [groupable]="false">
        <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
          <label class="k-form-field">
            <label class="k-checkbox-label">{{value}}</label>
            <!--Démat-->
            <button mat-icon-button color="primary" aria-label="démat" matTooltip="Dématerialiser ce client"
                    (click)="openFormDemat(group)" [disabled]="clientHaveAActifAcces(group)">
              <mat-icon>devices</mat-icon>
              <span *ngIf="group.items[0].demmat == 'O'">(Client dématérialisé)</span>
              <span *ngIf="group.items[0].demmat == 'N'">(Client non dématérialisé)</span>
              <span *ngIf="group.items[0].demmat == 'F'">(Client dématérialisé avec édition)</span>
            </button>
          </label>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="" width="80" [headerClass]="'tableHeader'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <img *ngIf="dataItem.code_legalyspace !== null" src="assets/images/signature3.png" class="responsive logo"
               alt="icon signature electronic"  matTooltip="Possede une signature">
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="cetatu" title="Code client" [groupable]="false" [filterable]="false" width="100"
                         [headerClass]="'tableHeader'" [resizable]="false" [sortable]="false">
      </kendo-grid-column>
      <kendo-grid-column field="contact" title="Contact" [groupable]="false" [filterable]="false" width="250"
                         [headerClass]="'tableHeader'" [sortable]="false">
      </kendo-grid-column>
      <kendo-grid-column field="id_user" title="Code utilisateur" [groupable]="false" [filterable]="false" width="130"
                         [headerClass]="'tableHeader'" [resizable]="false" [sortable]="false">
      </kendo-grid-column>
      <kendo-grid-column field="login" title="Identifiant" [groupable]="false" [filterable]="false" width="100"
                         [headerClass]="'tableHeader'"
                         [sortable]="false">
      </kendo-grid-column>
      <kendo-grid-column field="email" title="Email" [groupable]="false" [filterable]="false" width="200"
                         [headerClass]="'tableHeader'"
                         [sortable]="false">
      </kendo-grid-column>
      <kendo-grid-column field="is_actif" title="État" [groupable]="false" width="100" [headerClass]="'tableHeader'"
                         [sortable]="false">
        <ng-template kendoGridFilterCellTemplate let-filter>
          <app-dropdownlist-filter [filter]="filter" [data]="etats" textField="is_actif" valueField="is_actif">
          </app-dropdownlist-filter>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="dat_created" title="Date" [groupable]="false" [filterable]="false" width="160"
                         [headerClass]="'tableHeader'"
                         [sortable]="false" [resizable]="false">
      </kendo-grid-column>
      <kendo-grid-column field="demmat" title="Démat" [groupable]="false" width="100" [filterable]="true"
                         [headerClass]="'tableHeader'" [sortable]="false">
        <ng-template kendoGridFilterCellTemplate let-filter>
          <app-dropdownlist-filter [filter]="filter" [data]="demats" textField="demmat" valueField="demmat">
          </app-dropdownlist-filter>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="code_legalyspace" title="LegalySpace" [groupable]="false" [filterable]="false"
                         width="110"
                         [headerClass]="'tableHeader'"
                         [sortable]="false" [resizable]="false">
      </kendo-grid-column>
      <kendo-grid-messages
        noRecords="Aucun client trouvé">
      </kendo-grid-messages>
      <kendo-grid-messages
        [pagerItemsPerPage]="'custom items per page text'"
        [pagerItems]="''"
        [pagerOf]="'sur'"
        [pagerPage]="'custom Page text'"
      >
      </kendo-grid-messages>
    </kendo-grid>
  </div>
</div>

import { User } from '@app/shared/models/user.model';
import {Agency} from "@shared/models/agence";
import { AGENCE_99 } from '@app/shared/constants';

export class UserHelper {
    private static user: User | null = null;
    public static getUser() {
      if(UserHelper.user === null) {
        const user = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null;

        if (user !== null) {
          const agenciesWithName =  sessionStorage.getItem('agencies') ? JSON.parse(sessionStorage.getItem('agencies')) : null;
           //reduire la taille du nom de l'agence si il est superière à 22 caractère
          if(agenciesWithName !==null) {
            for( let i=0; i<user.agencies.length; i++) {
              let ag = agenciesWithName.find(agency => agency.coge === user.agencies[i].coge);
              if(ag) {
                if( ag.rs.length<22) {
                  user.agencies[i].rs = ag.rs.toLocaleUpperCase();
                }else {
                  user.agencies[i].rs = ag.rs.substring(0, 18).concat("..").toLocaleUpperCase();
                }
              }
            }
          }
          // ON UTILISE PAS L'AGENCE 99
          UserHelper.user = new User(user.coge, user.identifiant, user.nom, user.token, user.applications, user.agencies.filter(agency => agency.coge !== AGENCE_99));
        }
      }
      return UserHelper.user;
    }

    public static setUser(user: object) {
        sessionStorage.setItem('user', JSON.stringify(user));
    }

    public static switchAgency(coge: string) {
      let agency: Agency[] =  UserHelper.user.getAgencies().filter(agency => agency.coge ===coge);
      UserHelper.user.setCoge(agency[0].coge);
      UserHelper.user.setToken(agency[0].token);
    }

    public static setAgency(agency: string) {
      if(UserHelper.user !== null) {
        UserHelper.user.setCoge(agency);
      }
    }

    public static removeUser() {
        sessionStorage.removeItem('user');
        UserHelper.user = null;
    }
}

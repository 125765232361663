<div class="mainBox" fxLayout="column">
  <div class="titleBox">
    <span class="titleA">Gestion des annonces</span>
  </div>
  <!--Table des commerciaux -->
  <div class="contentBox" fxLayout="row" fxLayout.md="column" fxLayoutAlign.md="start center"  fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutAlign="start start" fxLayoutAlign.sm="start center" fxLayoutAlign.xs="start center">
    <mat-card class="mat-elevation-z0 full-screen">
      <form [formGroup]="adForm" class="form" fxLayout="column" fxLayoutAlign="" fxLayoutGap="15px" (ngSubmit)="onSubmit()">
        <span class="title">Gérer l'annonce </span>
        <mat-form-field appearance="fill">
          <mat-label>Numero de l'annonce</mat-label>
          <input matInput name="id_annonce" formControlName="id_annonce" [(ngModel)]="adForm.value.id_annonce" required readonly>
          <mat-error *ngIf="adForm.controls.id_annonce.hasError('required')">Champ obligatoire</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Date de validité</mat-label>
          <input type="date" matInput name="date_validite" formControlName="date_validite" [(ngModel)]="adForm.value.date_validite" required>
          <mat-error *ngIf="adForm.controls.date_validite.hasError('required')">Champ obligatoire</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Client</mat-label>
          <input matInput name="client" formControlName="client" [(ngModel)]="adForm.value.client" required>
          <mat-error *ngIf="adForm.controls.client.hasError('required')">Champ obligatoire</mat-error>
        </mat-form-field>
        <mat-grid-list cols="2" rowHeight="70px">
          <mat-grid-tile>
            <mat-form-field appearance="fill">
              <mat-label>Date de début</mat-label>
              <input type="date" matInput name="date_debut" formControlName="date_debut" [(ngModel)]="adForm.value.date_debut" placeholder="20/12/2021">
              <mat-error *ngIf="adForm.controls.date_debut.hasError('required')">Champ obligatoire</mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="fill">
              <mat-label>Date de fin</mat-label>
              <input type="date" matInput name="date_fin" formControlName="date_fin" [(ngModel)]="adForm.value.date_fin" placeholder="20/12/2021">
              <mat-error *ngIf="adForm.controls.date_fin.hasError('required')">Champ obligatoire</mat-error>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-form-field appearance="fill">
          <mat-label>Horaire</mat-label>
          <input  matInput name="horaire" formControlName="horaire" [(ngModel)]="adForm.value.horaire" required>
          <mat-error *ngIf="adForm.controls.horaire.hasError('required')">Champ obligatoire</mat-error>
        </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Type de contrat</mat-label>
            <select matNativeControl name="type_contract" [(ngModel)]="adForm.value.type_contract" required formControlName="type_contract">
              <option [ngValue]="'M'">Mission</option>
              <option [ngValue]="'C'">CDD</option>
            </select>
          </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Code postal</mat-label>
          <input matInput name="code_postal" formControlName="code_postal" [(ngModel)]="adForm.value.code_postal" required>
          <mat-error *ngIf="adForm.controls.code_postal.hasError('required')">Champ obligatoire</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Ville</mat-label>
          <input matInput name="ville" formControlName="ville" [(ngModel)]="adForm.value.ville" required>
          <mat-error *ngIf="adForm.controls.ville.hasError('required')">Champ obligatoire</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Intitulé du poste</mat-label>
          <input matInput name="titre" formControlName="titre" [(ngModel)]="adForm.value.titre" required>
          <mat-error *ngIf="adForm.controls.titre.hasError('required')">Champ obligatoire</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Détail de l'annonce</mat-label>
          <textarea matInput name="detail_annonce" formControlName="detail_annonce" [(ngModel)]="adForm.value.detail_annonce" required  style="min-height:200px;">

          </textarea>
          <mat-error *ngIf="adForm.controls.detail_annonce.hasError('required')">Champ obligatoire</mat-error>
        </mat-form-field>
<!--        <mat-form-field appearance="fill">-->
<!--          <mat-label>Code Commerciale</mat-label>-->
<!--          <input matInput name="ccomer" formControlName="ccomer" [(ngModel)]="commercialForm.value.ccomer" required>-->
<!--          <mat-error *ngIf="commercialForm.controls.ccomer.hasError('required')">Champ obligatoire</mat-error>-->
<!--        </mat-form-field>-->
<!--        <mat-form-field appearance="fill">-->
<!--          <mat-label>Portable</mat-label>-->
<!--          <input class="shepherd-button-033" matInput name="portable" formControlName="portable"/>-->
<!--        </mat-form-field>-->
<!--        <mat-form-field appearance="fill">-->
<!--          <mat-label>Adresse mail</mat-label>-->
<!--          <input class="shepherd-button-033" matInput name="email" formControlName="email" required-->
<!--                 pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"/>-->
<!--          <mat-error *ngIf="commercialForm.controls.email.hasError('required')">Champ obligatoire</mat-error>-->
<!--          <mat-error *ngIf="commercialForm.controls.email.hasError('pattern')">Email invalide</mat-error>-->
<!--        </mat-form-field>-->
        <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="15px">
          <button mat-button type="submit" class="valider">Publier</button>
        </div>
      </form>
    </mat-card>
  </div>
</div>

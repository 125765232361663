import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthData } from '@app/shared/models/auth-data.model';
import { WEBSERVICE_ADDR } from '@app/shared/constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserHelper } from '@app/helpers/user.helper';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private router: Router, private http: HttpClient,
    private snackBar: MatSnackBar ) {}

  login(data: AuthData) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    let url = WEBSERVICE_ADDR + 'commercial/login';

    this.http.post(url, JSON.stringify(data), { headers: headers, observe: "response" })
      .subscribe((response: any) => {
        if (response.status === 200) {
          let body = response.body
          if ("token" in body && "coge" in body) {
            UserHelper.setUser(body);
            this.router.navigate(['tableau-de-bord']);
          } else {
            this.snackBar.open("Une erreur est survenue.", null, { duration: 4000 });
          }
        }
      },
        error => {
          if (error.error instanceof ErrorEvent) {
            console.log(error.error.message);
          }
        }
      );
  }

  verify(token: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    let url = WEBSERVICE_ADDR + `commercial/authentication/${token}`;

    this.http.post(url,null, { headers: headers, observe: "response" })
      .subscribe((response: any) => {
          if (response.status === 200) {
            let body = response.body
            if ("token" in body && "coge" in body) {
              UserHelper.setUser(body);
              this.router.navigate(['tableau-de-bord']);
            } else {
              this.snackBar.open("Une erreur est survenue.", null, { duration: 4000 });
            }
          }
        },
        error => {
          if (error.error instanceof ErrorEvent) {
            console.log(error.error.message);
          }
        }
      );
  }

  logout() {
    UserHelper.removeUser();
    sessionStorage.clear();
    this.router.navigateByUrl('authentification');
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogActionsService } from '@app/shared/services/dialog-actions.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private dialogActionsService: DialogActionsService) { 
  }

  ngOnInit(): void {
  }

  actionFunction() {
    this.dialogActionsService.action(this.modalData);
    this.closeModal();
  }

  closeModal() {
    this.dialogRef.close();
  }

}

import { AfterViewInit } from '@angular/core';

export class ShepherdsHelper { 
    private static state: boolean = false;
    private static currentComponent: AfterViewInit|null;

    public static getState(): boolean {
        return this.state;
    }

    public static setState(state: boolean) {
        this.state = state
    }

    public static setCurrentComponent($component :AfterViewInit): void 
    {
        this.currentComponent = $component
    }

    public static getCurrentComponent(): AfterViewInit|null 
    {
        return this.currentComponent;
    }

}
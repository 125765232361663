import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/services/authentication.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Agency } from '@app/shared/models/agence';
import { PreLoadApplicationService } from '@app/services/pre-load-application.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
  hide = true;
  agencies: Agency[];
  signinForm : FormGroup;

  constructor(
    private authService: AuthenticationService,
    private preLoadApplicationService: PreLoadApplicationService) { }

  ngOnInit(): void {
     this.preLoadApplicationService.getAgences().subscribe(
       (resp: Agency[])=> {
        this.agencies = resp;
        sessionStorage.setItem('agencies', JSON.stringify(this.agencies));
       });

    this.signinForm = new FormGroup({
      coge: new FormControl('', [Validators.required]),
      identifiant: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
  }

  signin() {
    if(this.signinForm.valid) {
      this.authService.login({ coge: this.signinForm.value.coge, identifiant: this.signinForm.value.identifiant, password: this.signinForm.value.password});
    }
  }
}

import {Agency} from "@shared/models/agence";

export class User {
    coge: string;
    identifiant: string;
    nom: string;
    token?: string;
    applications: object;
    agencies: Agency[];
    constructor(coge: string, identifiant: string, nom: string, token: string, applications: object, agencies: Agency[])  {
        this.coge = coge;
        this.identifiant = identifiant;
        this.nom = nom;
        this.token = token;
        this.applications = applications;
        this.agencies = agencies;
    }

    setCoge(coge: string) {
      this.coge = coge;
    }

    setToken(token: string) {
      this.token = token;
    }

    getAgencies() {
      return this.agencies;
    }
}

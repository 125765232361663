import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';

import { PageChangeEvent,RowClassArgs } from '@progress/kendo-angular-grid';
import { ClientService } from '@app/services/client.service';
import {AllClientsListItem, Client, UpdateClientModel} from '@app/shared/models/client-model';
import { SortDescriptor, GroupDescriptor } from '@progress/kendo-data-query';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from '@app/shared/components/dialog/dialog.component';
import { finalize } from 'rxjs/operators';
import { Shepher } from '@app/shared/models/shepher-model';
import { ShepherdsHelper } from '@app/helpers/shepherds.helper';
import { ShepherdInternServiceService } from '@app/services/shepherd-intern-service.service';
import { ActivatedRoute } from '@angular/router';
import {Location} from "@angular/common";
import {Router} from "@angular/router";
import {Observable, Subscription} from "rxjs";

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit, AfterViewInit {
  public gridView: any[];
  public pageSize = 100;
  public skip = 0;
  private items: AllClientsListItem[] = [];
  public multiple = true;
  public allowUnsort = true;
  public sort: SortDescriptor[] = [{ field: 'rscli', dir: 'asc' }];
  public groups: GroupDescriptor[] = [{ field: 'rscli' }];
  dematForm: FormGroup;
  updateForm: FormGroup;
  updateData: UpdateClientModel;
  seeFormDemat: boolean = false;
  seeFormUpdate: boolean = false;
  updateEmail: boolean;
  updateLogin: boolean;
  public etats: any[];
  public demats: any[];
  defaultValueEtats: string = 'Tous';
  rscli: string = '';
  clientContact: string = '';
  cetatu: string = '';
  public loading: boolean;
  stepsArray: Shepher[];
  request: Subscription;

  constructor(
    private clientService: ClientService,
    public matDialog: MatDialog,
    private fb: FormBuilder,
    private shepherdInternService: ShepherdInternServiceService,
    private route: ActivatedRoute,
    private router: Router,
    public location: Location) { }

  ngOnInit(): void {
    this.loading = true;
    this.getAllClients();
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
  }

  loadHelpInline() {
    // Current Path:  company
    this.stepsArray = [];

    // créeation de l'array des steps
    this.stepsArray.push(new Shepher('clients', '.shepherd-button-01'
      , ["Cet onglet contient un tableau des accès par clients."], false, true));

    this.stepsArray.push(new Shepher('clients', '.shepherd-button-acces-contacts'
      , ["Cet onglet contient un tableau des accès par contact."], true, true));

    this.stepsArray.push(new Shepher('creerUnAccesClient', '.shepherd-button-02'
      , ["Cet onglet permet de créer un accès client."], true, true));

    this.stepsArray.push(new Shepher('envoyerMessageClient', '.shepherd-button-03'
      , ["Cet onglet permet d'envoyer un message  qui s'affichera dans le portail CLIP d'un client."],
      true, true));
    this.stepsArray.push(new Shepher('historiqueMessagesClient', '.shepherd-button-04b'
      , ["Cet onglet contient l'historique des activations d'accès des comptes clients."],
      true, true));

    this.stepsArray.push(new Shepher('rechercheUnClient', '.shepherd-button-011'
      , ["Rechercher un client par son nom ou nom de l'un de ses contacts."], true, true));
    this.stepsArray.push(new Shepher('tableDesClients', '.shepherd-button-012'
      , ["Tableau des accès clients existants. Chaque page du tableau peut être trié par numéro, par client," +
      " par contact ou les trois à la foi. On peut filtrer sur l’état (Actif, Inactif ou Tous), filtrer " +
      "sur Demat(O:oui, N:non ou Tous)"], true, false, true));
    //ajouter les steps dans  ShepherdHelper
    this.shepherdInternService.createTourOfSteps(this.stepsArray);

  }

  ngAfterViewInit(): void {

    ShepherdsHelper.setCurrentComponent(this);
    this.shepherdInternService.removeStep();
    if (ShepherdsHelper.getState() === true) {
      this.loadHelpInline();
    }
  }

  getAllClients() {
    this.items = [];
    this.clientService.getAllClients(0, this.pageSize)
      .pipe(finalize(() => {
        this.loadItems();
      }))
      .subscribe(res => {
        res.forEach(client => {
          this.items.push({
            id_user: client['id_user'],
            coge: client['coge'],
            contact: client['contact'],
            login: client['login'],
            email: client['email'],
            is_signataire: client['is_signataire'],
            is_invoicing: client['type_contact'] == 'F',
            is_actif: client['is_actif'] == 1 ? 'Actif' : 'Inactif',
            dat_created: client['dat_created'],
            credentials: client['credentials'],
            compte_en_lys: client['compte_en_lys'],
            code_legalyspace: client['code_legalyspace'],
            dactiv: client['dactiv'],
            demmat: client['demmat'],
            cetatu: client['cetatu'],
            rscli: client['rscli']
          })
        })
      }
    );
  }

  private loadItems(): void {
    this.gridView = this.items;
    if(this.gridView.length > 0) {
      this.loading = false;
    }
    this.demats = this.items.map(item => item.demmat)
      .filter((value, index, self) => self.indexOf(value) === index);
    this.demats.push(this.defaultValueEtats);
    this.etats = this.items.map(item => item.is_actif)
      .filter((value, index, self) => self.indexOf(value) === index);
    this.etats.push(this.defaultValueEtats);
  }

  public groupChange(groups: GroupDescriptor[]): void {
    this.groups = groups;
    this.loadItems();
  }

  public rowCallback(context: RowClassArgs) {
    const isEven = context.index % 2 == 0;
    return {
      even: isEven,
      odd: !isEven
    };
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadItems();
  }

  onSearch(filter) {
    const searchString: string = filter.trim();
    let clients: AllClientsListItem[] = [];
    if (searchString.length > 0) {
      this.loading = true;
      this.request = this.clientService.searchClientsInClip(searchString)
        .subscribe((res: any[]) => {
          res.forEach(client => {
            clients.push({
              id_user: client['id_user'],
              coge: client['coge'],
              contact: client['contact'],
              login: client['login'],
              email: client['email'],
              is_signataire: client['is_signataire'],
              is_invoicing: client['type_contact'] == 'F',
              is_actif: client['is_actif'] == 1 ? 'Actif' : 'Inactif',
              dat_created: client['dat_created'],
              credentials: client['credentials'],
              compte_en_lys: client['compte_en_lys'],
              code_legalyspace: client['code_legalyspace'],
              dactiv: client['dactiv'],
              demmat: client['demmat'],
              cetatu: client['cetatu'],
              rscli: client['rscli']
            })
          }
          );
          this.loading = false;
          //allowe Kendo angular pager will reset back to the first page
          this.pageChange({skip: 0, take:0});
          this.gridView = clients;
        },() =>console.log("error"),
          () => console.log("complete"))
      //this.request.unsubscribe();
    } else {
      this.getAllClients();
    }
  }

  updateLoginUpdateEmail(etat: any, legalyspace: any) {
    /**
     * On ne peut pas modifier l'email d'un compte actif avec legalyspace, seul son identifiant est modifiable
     * on peut modifier l' identifiant et l' email d'un compte inactif et d'un compte actif sans legalyspace,
     */
    if (etat === "Inactif" || (etat === "Actif" && !legalyspace)) {
      this.updateLogin = true;
      this.updateEmail = true;
    } else if (etat === "Actif" && legalyspace) {
      this.updateLogin = true;
      this.updateEmail = false;
    } else {
      this.updateLogin = false;
      this.updateEmail = false;
    }
  }

  clientHaveAActifAcces(group): boolean {
    let items: any[] = group.items;
    let clientHavenotActifAcces: boolean = true;
    let i = 0;

    while (i < items.length && clientHavenotActifAcces) {
      if (items[i]?.is_actif === 'Actif') {
        clientHavenotActifAcces = false;
      }
      i++;
    }
    return clientHavenotActifAcces;
  }

  openFormDemat(group) {
    //on ne peut dematerialiser un client, que s'il possède un accès avec une signature electronique
    this.rscli = group.items[0]?.rscli;
    this.cetatu = group.items[0]?.cetatu;
    this.dematForm = this.fb.group({
      'statut_demat': [group.items[0]?.demmat, Validators.required]
    });
    this.seeFormDemat = true;
    this.seeFormUpdate = false;
  }

  onRenvoiMailActivationt(dataItem) {
    this.clientService.renvoiMailDactivationt(dataItem.id_user);
  }

  demat() {
    if (this.dematForm.valid && this.cetatu) {
      this.clientService.setDemat(this.cetatu, this.dematForm.value.statut_demat);
      this.seeFormDemat = false;
    }
    window.location.reload();
  }

  cancelDemat() {
    this.seeFormDemat = false;
  }

  openFormUpdate(dataItem) {
    this.clientContact = dataItem.contact;
    //afficher ou non le champ login  ou/et email
    this.updateLoginUpdateEmail(dataItem.is_actif, dataItem.code_legalyspace);

    this.updateForm = new FormGroup({
      login: new FormControl(dataItem.login, [Validators.required]),
      email: new FormControl(dataItem.email, [Validators.required,
        Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")]),
      is_invoicing: new FormControl(dataItem.is_invoicing, Validators.required)
    });

    this.seeFormUpdate = true;
    this.seeFormDemat = false;
    this.updateData = new UpdateClientModel(dataItem.id_user, dataItem.contact, dataItem.login, dataItem.email, dataItem.is_invoicing, dataItem.cetatu);
  }

  update() {
    if (this.updateForm.valid) {
      this.clientService.update(new UpdateClientModel(this.updateData.id_user, this.updateData.contact,
        this.updateLogin ? this.updateForm.value.login : this.updateData.login,
        this.updateEmail ? this.updateForm.value.email : this.updateData.email,this.updateForm.value.is_invoicing, this.updateData.cetatu));
    }
    this.refreshComponent();
  }

  cancelUpdate() {
    this.seeFormUpdate = false;
  }
  onDelete(dataItem) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;// The user can't close the dialog by clicking outside its body
    dialogConfig.id = "modal-component";
    dialogConfig.height = "200px";
    dialogConfig.width = "360px";
    dialogConfig.data = {
      name: "deleteClient",
      title: "Demande de confirmation",
      description: "Etes-vous certain de vouloir supprimer ce client ?",
      actionButtonText: "Oui",
      idUser: dataItem.id_user,
      cetatu: dataItem.cetatu,
      component: this,
    }
    this.matDialog.open(DialogComponent, dialogConfig);
  }

  onOpenUserCompteInAdminMode(dataItem) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;// The user can't close the dialog by clicking outside its body
    dialogConfig.id = "modal-component";
    dialogConfig.height = "250px";
    dialogConfig.width = "380px";
    dialogConfig.data = {
      name: "openClientCompteInAdminMode",
      title: "Demande de confirmation",
      description: "Voulez vous accéder à ce compte CLIP en mode administrateur?",
      description2:"En mode administrateur, seule la consultation des informations est possible, aucune modification " +
        "ne peut être effectuée.",
      actionButtonText: "Oui",
      idUser: dataItem.id_user,
      component: this,
    }
    this.matDialog.open(DialogComponent, dialogConfig);
  }

  refreshComponent() {
    this.router.navigateByUrl("tableau-de-bord/refresh", {skipLocationChange: true}).then(() => {
      this.router.navigate([decodeURI(this.location.path())]);
    })
  }
}


<div fxLayout="column" flexfill>
  <div fxFlex="10%" class="searchBox">
    <div class="searchDiv" fxLayout="row wrap" flexfill>
        <label [for]="'input'">Rechercher un intérimaire dans PEGASE :</label>
        <input class="k-textbox shepherd-button-121" (input)="onSearch($event.target.value)" placeholder="Effectuez la recherche par numéro intérimaire ou nom" />
      </div>
  </div>
  <!--Formulaire de creation de d'accès isi-->
  <div fxFlex="10%" class="createIsiClipBox shepherd-button-123" *ngIf="seeForm === true" >
    <mat-card>
      <span class="title">Créer un accès intérimaire</span>
      <form [formGroup]="newInterimaireForm" id="update-form" fxLayout="row wrap"  fxLayoutGap="20px"
      (ngSubmit)="createAccesIsiCompte()">
      <mat-form-field >
        <mat-label>Nom et Prénom</mat-label>
        <input matInput  name="contact"  formControlName="contact" #contact required>
        <mat-error>Champ obligatoire</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput name="email" formControlName="email" #email required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
        <mat-error *ngIf="newInterimaireForm.controls.email.hasError('required')">Champ obligatoire</mat-error>
        <mat-error *ngIf="newInterimaireForm.controls.email.hasError('pattern')">Email invalide</mat-error>
      </mat-form-field>
      <mat-radio-group fxLoyaout="row" fxLayoutGap="5px" formControlName="is_signataire">
        <mat-label>Signature :</mat-label>
        <mat-radio-button [value]=true name="true">Oui</mat-radio-button>
        <mat-radio-button [value]=false name="false">Non</mat-radio-button>
      </mat-radio-group>
      <button mat-button type="submit" class="valider" >Valider</button>
      <button mat-button (click)="cancel()"> Annuler</button>
    </form>
    </mat-card>
  </div>
  <div class="loader" *ngIf="loading === true"></div>
  <div fxFlex="70%" class="tableContentBox" *ngIf="seeTable">
    <kendo-grid [data]="gridView"
                [selectable]="{enabled: true,checkboxOnly: true, mode: 'single'}"
                [resizable]="true"
                [pageSize]="pageSize"
                [skip]="skip"
                [pageable]="true"
                [style.height.px]="600"
                (selectionChange)="selectionChange($event)">
     <kendo-grid-checkbox-column class="shepherd-button-122" width="50" [headerClass]="'tableHeader'"></kendo-grid-checkbox-column>
      <kendo-grid-column field="nsala" title="Numéro intérimaire" [headerClass]="'tableHeader'"></kendo-grid-column>
      <kendo-grid-column field="typ" title="Civilité" [headerClass]="'tableHeader'"></kendo-grid-column>
      <kendo-grid-column field="nom" title="Nom" [headerClass]="'tableHeader'"[sortable]="false"></kendo-grid-column>
      <kendo-grid-column field="prenom" title="Prénom" [headerClass]="'tableHeader'"></kendo-grid-column>
      <kendo-grid-column field="email" title="Email" [headerClass]="'tableHeader'" [sortable]="false"></kendo-grid-column>
      <kendo-grid-column field="njf" title="Nom de jeune fille" [headerClass]="'tableHeader'"></kendo-grid-column>
      <kendo-grid-messages groupPanelEmpty="Drag here to group" noRecords="Aucun intérimaire trouvé"></kendo-grid-messages>
      <kendo-grid-messages
        [pagerItemsPerPage]="'custom items per page text'"
        [pagerItems]="''"
        [pagerOf]="'sur'"
        [pagerPage]="'custom Page text'"
      >
      </kendo-grid-messages>
    </kendo-grid>
  </div>
</div>

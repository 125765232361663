<div class="mainBox" fxLayout="column" flexfill>
  <div class="searchBoxc">
    <!--searchBox Contact-->
    <div class="searchDiv" fxLayout="row wrap" flexfill>
        <label [for]="'input'" class="k-labeltbox">Rechercher un accès client :</label>
        <input class="k-textbox shepherd-button-311" (input)="onSearchContact($event.target.value)"
               placeholder="Effectuez la recherche par client ou contact"/>
    </div>

    <!-- Associate client to contact -->
    <mat-card class="marginCart" *ngIf="openFormAssociateClientToConctact === true">
      <div>
        <span class="title">Associer un client au contact {{contact}} </span>
      </div>
      <!--searchBox Client-->
      <div class="searchClient">
        <div fxLayout="row wrap" flexfill>
          <div class="searchDiv">
            <label [for]="'input'">Rechercher un client: </label>
            <input class="k-textbox  shepherd-button-searchClient" (input)="onSearchClient($event.target.value)"
                   placeholder="Effectuez la recherche par son code ou raison sociale"/>
          </div>
        </div>
      </div>
      <!--Table des client -->
      <div class="tableContent">
        <kendo-grid [data]="gridViewClient"
                    [resizable]="true"
                    [pageSize]="pageSizeClient"
                    [skip]="skipClient"
                    [pageable]="true"
                    [style.height.px]="250">
          <kendo-grid-column field="cetatu" width="100" title="Code client"
                             [headerClass]="'tableHeader'"></kendo-grid-column>
          <kendo-grid-column class="shepherd-button-tabClient" field="rscli" width="300" title="Raison sociale"
                             [headerClass]="'tableHeader'"></kendo-grid-column>
          <kendo-grid-command-column title="Action" [headerClass]="'tableHeader'" [resizable]="false">
            <ng-template kendoGridCellTemplate let-dataItem>
              <form [formGroup]="newClientForm" fxLayout=" row wrap" fxLayoutAlign="" fxLayoutGap="15px"
                    (ngSubmit)="createCompteClip(dataItem)">
                <mat-radio-group fxLoyaout="row" fxLayoutGap="5px" formControlName="is_signataire">
                  <mat-label>Signature electronique :</mat-label>
                  <mat-radio-button [value]=true name="true">Oui</mat-radio-button>
                  <mat-radio-button [value]=false name="false">Non</mat-radio-button>
                </mat-radio-group>
                <button mat-button type="submit" class="Accocier shepherd-button-clientContact">Associer ce client à ce contact</button>
              </form>
            </ng-template>
          </kendo-grid-command-column>
          <kendo-grid-messages
            noRecords="Aucun client trouvé">
          </kendo-grid-messages>
        </kendo-grid>
      </div>
      <div class="annuler" fxLayout="row" fxLayoutAlign="center">
        <button mat-button (click)="close()">Fermer</button>
      </div>
    </mat-card>
  </div>
  <div class="loader" *ngIf="loading === true"></div>
  <!--Table des contacts -->
  <div class="tableContentBoxc">
    <kendo-grid [kendoGridBinding]="gridViewConctact"
                [groupable]="true"
                [group]="groups"
                [filterable]="true"
                [pageSize]="pageSizeContact"
                [skip]="skipContact"
                [resizable]="true"
                [pageable]="true"
                [sortable]="{ allowUnsort: allowUnsort,mode: 'multiple'}"
                [selectable]="{ checkboxOnly: false, mode: 'simple' }"
                [sort]="sort"
                (groupChange)="groupChangeContact($event)"
                (sortChange)="sortChangeContact($event)"
                (pageChange)="pageChangeContact($event)"
                [style.height]="'100%'"
                [rowClass]="rowCallback">
      <kendo-grid-command-column title="Action" width="100" [headerClass]="'tableHeader'" [resizable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
          <!-- Supprimer-->
          <button mat-icon-button class='red-icon' aria-label="supprimer" matTooltip="Supprimer"
                  (click)="onDelete(dataItem)"
                  [disabled]="(dataItem.is_actif==='Actif') || (dataItem.is_actif==='Inactif' && dataItem.code_legalyspace)">
            <mat-icon>delete</mat-icon>
          </button>
        </ng-template>
      </kendo-grid-command-column>

      <kendo-grid-column field="contact" title="Contact" [groupable]="false" [filterable]="false" width="250"
                         [headerClass]="'tableHeader'">
        <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
          <label class="k-form-field">
            <label class="k-checkbox-label">{{value}}</label>
            <!--openFormAssociateClientToContact-->
            <button mat-icon-button class="icon-add-client" aria-label="Associer un client à ce contact"
                    matTooltip="Associer un client à ce contact"
                    (click)="openFormAssociateClientToContact(group)">
              <mat-icon>person_add</mat-icon>
            </button>
          </label>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column class="shepherd-button-312" field="rscli" title="Client" [groupable]="false" [filterable]="false" width="250"
                         [headerClass]="'tableHeader'" [sortable]="false">
      </kendo-grid-column>

      <kendo-grid-column title="" width="80" [headerClass]="'tableHeader'" [sortable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
          <img *ngIf="dataItem.code_legalyspace !== null" src="assets/images/signature3.png" class="responsive logo"
               alt="icon signature electronic">
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="" width="50" [headerClass]="'tableHeader'" [sortable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
          <img *ngIf="dataItem.is_invoicing === true" src="assets/images/invoicing.png" class="logo invoice"
               alt="icon invoice" matTooltip="Contact Facturation">
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="cetatu" title="Code client" [groupable]="false" [filterable]="false" width="100"
                         [headerClass]="'tableHeader'" [resizable]="false" [sortable]="false">
      </kendo-grid-column>
      <kendo-grid-column field="id_user" title="Code utilisateur" [groupable]="false" [filterable]="false" width="130"
                         [headerClass]="'tableHeader'" [resizable]="false" [sortable]="false">
                         [headerClass]="'tableHeader'" [resizable]="false" [sortable]="false">
      </kendo-grid-column>
      <kendo-grid-column field="login" title="Identifiant" [groupable]="false" [filterable]="false" width="100"
                         [headerClass]="'tableHeader'"
                         [sortable]="false">
      </kendo-grid-column>
      <kendo-grid-column field="email" title="Email" [groupable]="false" [filterable]="false" width="200"
                         [headerClass]="'tableHeader'"
                         [sortable]="false">
      </kendo-grid-column>
      <kendo-grid-column field="is_actif" title="État" [groupable]="false" width="100" [headerClass]="'tableHeader'"
                         [sortable]="false">
        <ng-template kendoGridFilterCellTemplate let-filter>
          <app-dropdownlist-filter [filter]="filter" [data]="etats" textField="is_actif" valueField="is_actif">
          </app-dropdownlist-filter>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="dat_created" title="Date" [groupable]="false" [filterable]="false" width="160"
                         [headerClass]="'tableHeader'"
                         [sortable]="false" [resizable]="false">
      </kendo-grid-column>
      <kendo-grid-column field="demmat" title="Démat" [groupable]="false" [filterable]="false" width="70"
                         [headerClass]="'tableHeader'"
                         [sortable]="false" [resizable]="false">
      </kendo-grid-column>
      <kendo-grid-column field="code_legalyspace" title="LegalySpace" [groupable]="false" [filterable]="false"
                         width="110" [headerClass]="'tableHeader'"
                         [sortable]="false" [resizable]="false">
      </kendo-grid-column>
      <kendo-grid-messages
        noRecords="Aucun accès trouvé">
      </kendo-grid-messages>
      <kendo-grid-messages
        [pagerItemsPerPage]="'custom items per page text'"
        [pagerItems]="''"
        [pagerOf]="'sur'"
        [pagerPage]="'custom Page text'"
      >
      </kendo-grid-messages>
    </kendo-grid>
  </div>

</div>

import { Component, OnInit } from '@angular/core';
import {GridDataResult, PageChangeEvent} from '@progress/kendo-angular-grid';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {InterimaireService} from '@app/services/interimaire.service';
import {finalize} from 'rxjs/operators';
import {MessageNotification} from '@shared/models/messageNotification';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DialogComponent} from '@shared/components/dialog/dialog.component';
import {Location} from '@angular/common';
import {Shepher} from '@shared/models/shepher-model';
import {ShepherdInternServiceService} from '@app/services/shepherd-intern-service.service';
import {ShepherdsHelper} from '@app/helpers/shepherds.helper';

@Component({
  selector: 'app-messages-isi',
  templateUrl: './messages-isi.component.html',
  styleUrls: ['./messages-isi.component.css']
})
export class MessagesIsiComponent implements OnInit {

  public gridView: GridDataResult;
  private totalLength: number;
  public pageSize = 100;
  public skip = 0;
  private items: any[] = [];
  private dataSource: MatTableDataSource<any>;
  filter: any = '';
  page: number;
  stepsArray: Shepher[] = [];
  public loading: boolean;

  constructor(
    private interimaireService: InterimaireService,
    private router: Router,
    public matDialog: MatDialog,
    private location: Location,
    private shepherdInternService: ShepherdInternServiceService) {
  }

  ngOnInit(): void {
    this.getMessages();
    this.loading = true;
  }

  ngAfterViewInit(): void {
    ShepherdsHelper.setCurrentComponent(this);
    this.shepherdInternService.removeStep();
    if (ShepherdsHelper.getState() === true) {
      this.loadHelpInline();
    }
  }

  loadHelpInline() {
    this.stepsArray = [];
    // créeation de l'array des steps
    this.stepsArray.push(new Shepher('chercherUnMessageIsi', '.shepherd-button-chercher-msg-isi'
      , ['Rechercher un message.'], false, true));
    this.stepsArray.push(new Shepher('messageIsi', '.shepherd-button-msg-isi'
      , ['Table des messages qui s\'affichent dans le portail ISI des interimaires.'], true, false, true));

    // ajouter les steps dans  ShepherdHelper
    this.shepherdInternService.createTourOfSteps(this.stepsArray);

  }

  getMessages() {
    this.interimaireService.getMessages()
      .pipe(finalize(() => {
        this.loadItems();
      }))
      .subscribe(response => {
          this.items = [];
          response.forEach(msg => {
            this.items.push(new MessageNotification(msg.id, msg.coge, msg.code, msg.typ,msg.nom, msg.titre, msg.dat,
              msg.ligne1, msg.daction, ''));
          });
          this.dataSource = new MatTableDataSource(this.items);
          this.totalLength = response.length;
        }
      );
  }

  private loadItems(): void {
    this.gridView = {
      data: this.items.slice(this.skip, this.skip + this.pageSize),
      total: this.items.length,
    };
    if (this.gridView.data.length > 0) {
      this.loading = false;
    }
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems();
  }

  public onFilter(inputValue: string): void {
    this.dataSource.filter = inputValue;
    this.items = this.dataSource.filteredData;
    this.loadItems();
  }

  public deleteMessage(dataItem): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true; // The user can't close the dialog by clicking outside its body
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '200px';
    dialogConfig.width = '360px';
    dialogConfig.data = {
      name: 'deleteMessageInterimaire',
      title: 'Demande de confirmation',
      description: 'Etes-vous certain de vouloir supprimer ce message ?',
      actionButtonText: 'Oui',
      idMessage: dataItem.id,
      component: this,
    };
    const modalDialog = this.matDialog.open(DialogComponent, dialogConfig);
  }

  refreshComponent() {
    this.router.navigateByUrl('tableau-de-bord/refresh', {skipLocationChange: true}).then(() => {
      this.router.navigate([decodeURI(this.location.path())]);
    });
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-dropdownlist-filter',
  template: `
    <kendo-dropdownlist
      [data]="data"
      (valueChange)="onChange($event)"
      [value]="defaultItem"
     >
    </kendo-dropdownlist>
  `,
  styleUrls: ['./dropdownlist-filter.component.css']
})
export class DropdownlistFilterComponent extends BaseFilterCellComponent {
  @Input() public filter: any;
  @Input() public data: any[];
  @Input() public textField: string;
  @Input() public valueField: string;
  defaultItem: string = 'Tous';

  constructor(filterService: FilterService) {
    super(filterService);
  }

  public get selectedValue(): any {
      const filter = this.filterByField(this.valueField);
      return filter ? filter.value : null;
  }

  public onChange(value: any): void {
      this.applyFilter(
          value === this.defaultItem? // value of the default item
              this.removeFilter(this.valueField) : // remove the filter
              this.updateFilter({ // add a filter for the field with the value
                  field: this.valueField,
                  operator: 'eq',
                  value: value
              })
      ); // update the root filter
  }
}

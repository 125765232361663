<div class="mainBox" fxLayout="column" flexfill>
  <div class="searchBoxc">
    <!--searchBox-->
    <div class="searchDiv" fxLayout="row wrap" flexfill>
    <label [for]="'input'" class="k-labeltbox">Rechercher une adresse mail :</label>
    <input class="shepherd-button-041" placeholder="Rechercher une adresse mail" kendoTextBox (input)="onFilter($event.target.value)"/>
    </div>
  </div>
  <div class="loader" *ngIf="loading === true"></div>
  <div class="tableContentBoxc">
  <kendo-grid
  [data]="gridView"
  [pageSize]="state.take"
  [skip]="state.skip"
  [pageable]="true"
  [style.height]="'100%'"
  [resizable]="true"
  (pageChange)="pageChange($event)"
  (dataStateChange)="dataStateChange($event)"
  >

    <kendo-grid-column  field="sender" title="Expéditeur" width="160" [headerClass]="'tableHeader'">
    </kendo-grid-column>
    <kendo-grid-column class="shepherd-button-042" field="recipients" title="Destinataire" width="160" [headerClass]="'tableHeader'">
    </kendo-grid-column>
    <kendo-grid-column field="subject" title="Sujet" width="160" [headerClass]="'tableHeader'">
    </kendo-grid-column>
    <kendo-grid-column field="sent" title="Etat" width="160" [headerClass]="'tableHeader'" [resizable]="false">
    </kendo-grid-column>
    <kendo-grid-column field="createdAt" title="Heure d'envoi" width="160" [headerClass]="'tableHeader'" [resizable]="false">
    </kendo-grid-column>
    <kendo-grid-column field="updatedAt" title="Heure de mise à jour" width="160" [headerClass]="'tableHeader'" [resizable]="false">
    </kendo-grid-column>
    <kendo-grid-column field="errorMessage" title="Message d'erreur" width="160" [headerClass]="'tableHeader'"></kendo-grid-column>
    <kendo-grid-messages groupPanelEmpty="Drag here to group" noRecords="Aucun message trouvé">
  </kendo-grid-messages>
    <kendo-grid-messages
      [pagerItemsPerPage]="'custom items per page text'"
      [pagerItems]="''"
      [pagerOf]="'sur'"
      [pagerPage]="'custom Page text'"
    >
    </kendo-grid-messages>
  </kendo-grid>
  </div>
</div>




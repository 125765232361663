import {Person, UpdatePersonModel} from './person-model';

export interface Client extends Person{
  cetatu: string;
  rscli: string;
  is_actif: number;
}

export interface AllClientsListItem extends Person {
  is_actif: string,
  is_invoicing: boolean|null,
  cetatu: string,
  rscli: string
}

export interface ClientInPegase {
  coge: string,
  cetatu: string,
  rscli: string
}

export class UpdateClientModel extends UpdatePersonModel {
  is_invoicing: boolean|null;
  cetatu: string;
  constructor(id_user?: number, contact?: string, login?: string, email?: string, is_invoicing?: boolean|null, cetatu?: string) {
    super(id_user, contact, login, email);
    this.is_invoicing = is_invoicing;
    this.cetatu = cetatu;
  }
}

export class Message {
    sender: string;
    recipients: string[];
    subject: string;
    sent: string;
    errorMessage: string;
    createdAt: any;
    updatedAt: any;
    constructor(sender: string, recipients: string[],  subject: string, sent: string, errorMessage: string, createdAt: any, updatedAt: any) {
        this.sender = sender;
        this.recipients = recipients;
        this.subject = subject;
        this.sent = sent;
        this.errorMessage = errorMessage;
        this.createdAt =  createdAt;
        this.updatedAt = updatedAt;
    }
}


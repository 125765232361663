import {Component, OnInit} from '@angular/core';
import {Agency} from '@app/shared/models/agence';
import {ActivatedRoute} from '@angular/router';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AdService} from "@app/services/ad.service";
import {finalize} from "rxjs/operators";
import {formatDate} from "@angular/common";

@Component({
  selector: 'app-ad-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.css']
})
export class UpdateComponent implements OnInit {
  hide = true;
  agencies: Agency[];
  private sub: any;
  public adForm: FormGroup;

  constructor(private route: ActivatedRoute, private adService: AdService) {

    this.adForm = new FormGroup({
      id_annonce: new FormControl(null),
      date_validite: new FormControl(null, Validators.required),
      date_debut: new FormControl(null),
      date_fin: new FormControl(null, Validators.required),
      horaire: new FormControl(null),
      type_contract: new FormControl(null),
      titre: new FormControl(null, Validators.required),
      code_postal: new FormControl(null, Validators.required),
      ville: new FormControl(null, Validators.required),
      detail_annonce: new FormControl(null, Validators.required),
      client: new FormControl(null, Validators.required),
    });
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      console.log(params['id']);
      this.adService.getAd(params['id'])
        .pipe(finalize(() => {
        }))
        .subscribe(response => {
            console.log(response);
            let date_validite = null;
            if (response.date_validite !== null) {
              date_validite = response.date_validite.split('/').reverse().join('-');
            }

          let date_debut = null;
          if (response.date_debut !== null) {
            date_debut = response.date_debut.split('/').reverse().join('-');
          }
          let date_fin = null;
          if (response.date_fin !== null) {
            date_fin = response.date_fin.split('/').reverse().join('-');
          }
            this.adForm.controls['type_contract'].setValue(response.type_contract, {onlySelf: true});
            this.adForm.controls['id_annonce'].setValue(response.id_annonce);
            this.adForm.controls['titre'].setValue(response.titre);
            this.adForm.controls['date_validite'].setValue(date_validite);
            this.adForm.controls['date_debut'].setValue(date_debut);
            this.adForm.controls['date_fin'].setValue(date_fin);
            this.adForm.controls['horaire'].setValue(response.horaire);
            this.adForm.controls['code_postal'].setValue(response.code_postal);
            this.adForm.controls['ville'].setValue(response.ville);
            this.adForm.controls['detail_annonce'].setValue(response.detail_annonce);
            this.adForm.controls['client'].setValue(response.client);
          }
        );
    });
  }

  public onSubmit(): void {
    this.adService.updateAd(this.adForm.controls['id_annonce'].value, this.adForm.getRawValue());
    // console.log(this.adForm.getRawValue())
  }
}

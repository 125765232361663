import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './components/homescreen/home/home.component';
import {DashboardscreenComponent} from './components/dashboardscreen/dashboardscreen/dashboardscreen.component';
import {AuthGuard} from './helpers/auth.guard';
import {SigninComponent} from './components/homescreen/signin/signin.component';
import {ClientsComponent} from './components/dashboardscreen/clip/clients/clients.component';
import {InterimairesComponent} from './components/dashboardscreen/isi/interimaires/interimaires.component';
import {SendMessageToClipCompteComponent} from './components/dashboardscreen/clip/send-message-to-clip-compte/send-message-to-clip-compte.component';
import {SendMessageToIsiCompteComponent} from './components/dashboardscreen/isi/send-message-to-isi-compte/send-message-to-isi-compte.component';
import {AddNewClientComponent} from './components/dashboardscreen/clip/add-new-client/add-new-client.component';
import {AddNewInterimaireComponent} from './components/dashboardscreen/isi/add-new-interimaire/add-new-interimaire.component';
import {MessageClipHistoryComponent} from './components/dashboardscreen/clip/message-clip-history/message-clip-history.component';
import {MessageIsiHistoryComponent} from './components/dashboardscreen/isi/message-isi-history/message-isi-history.component';
import {RefreshComponent} from './components/refresh/refresh.component';
import {ClientOrderManagersComponent } from './components/dashboardscreen/clip/client-order-managers/client-order-managers.component';
import {ContactComponent} from '@components/dashboardscreen/clip/contact/contact.component';
import {AuthComponent} from '@components/homescreen/auth/auth.component';
import {AdComponent} from '@components/dashboardscreen/ad/home/ad.component';
import {UpdateComponent} from '@components/dashboardscreen/ad/update/update.component';
import {MessagesClipComponent} from '@components/dashboardscreen/clip/messages-clip/messages-clip.component';
import {MessagesIsiComponent} from '@components/dashboardscreen/isi/messages-isi/messages-isi.component';

const routes: Routes = [
  {path: 'authentification', component: SigninComponent},
  {path: 'commercial/authentication/:token', component: AuthComponent},
  {
    path: 'tableau-de-bord', component: DashboardscreenComponent, canActivate: [AuthGuard],
    children: [
      {path: '', redirectTo: 'accueil', pathMatch: 'full'},
      {path: 'accueil', component: HomeComponent},
      {path: 'clip', component: ClientsComponent},
      {path: 'isi', component: InterimairesComponent},
      {path: 'ad', component: AdComponent},
      {path: 'ad/update/:id', component: UpdateComponent},
      {path: 'clip-contacts', component: ContactComponent},
      {path: 'message-clip', component: SendMessageToClipCompteComponent},
      {path: 'message-isi', component: SendMessageToIsiCompteComponent},
      {path: 'nouveau-acces-clip', component: AddNewClientComponent},
      {path: 'nouveau-acces-isi', component: AddNewInterimaireComponent},
      {path: 'message-clip-history', component: MessageClipHistoryComponent},
      {path: 'message-isi-history', component: MessageIsiHistoryComponent},
      {path: 'annuaires-des-destinataires-mails', component: ClientOrderManagersComponent},
      {path: 'refresh', component: RefreshComponent},
      {path: 'messages-clip', component: MessagesClipComponent},
      {path: 'messages-isi', component: MessagesIsiComponent}
    ]
  },
  {path: '**', redirectTo: 'tableau-de-bord/accueil', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}

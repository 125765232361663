export interface Person {
  id_user: number;
  coge: string;
  contact: string;
  login: string;
  email: string;
  is_signataire: number;
  dat_created: string,
  credentials: string;
  compte_en_lys: string;
  code_legalyspace: string;
  dactiv: string;
  demmat: string;
}

export class UpdatePersonModel {
  id_user: number;
  contact: string|null;
  login: string|null;
  email: string|null;
  constructor(id_user?: number, contact?: string, login?: string, email?: string) {
      this.id_user = id_user;
      this.contact = contact;
      this.login = login;
      this.email = email;
  }
}

import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import {MatGridListModule} from '@angular/material/grid-list';

import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule} from '@angular/material/select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';


const MaterialComponents = [MatInputModule,
  MatButtonModule,
  MatIconModule,
  MatGridListModule,
  MatFormFieldModule,
  MatTooltipModule,
  MatCheckboxModule,
  MatCardModule,
  MatListModule,
  MatToolbarModule,
  MatRadioModule,
  MatStepperModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSidenavModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatBadgeModule,
  MatSnackBarModule,
  MatProgressBarModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatButtonToggleModule,
  MatTableModule,
  MatPaginatorModule,
  MatSlideToggleModule,
]
@NgModule({
    imports: [
      MaterialComponents
    ],
    exports: [
      MaterialComponents
    ],
    providers: [
      {
        provide: MAT_DATE_LOCALE, useValue: 'fr-FR'
      },
    ]
})
export class MaterialModule {

}

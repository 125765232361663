<shared-layout>
    <div class="mainBox justify-center" fxLayout="column" fxLayoutAlign="start center" style="width: 100%">
    <mat-card class="signinCard">
    <div id="title">Identification</div>
    <form [formGroup]="signinForm" id="signin-form" fxLayout="column wrap" fxLayoutAlign="center center"
        fxLayoutGap="5px" (ngSubmit)="signin()" style="width: 100%">
        <mat-form-field>
            <mat-label>Agence</mat-label>
            <select matNativeControl name="coge" formControlName="coge" #coge required>
                <option *ngFor="let agency of agencies" [value]="agency.coge">{{agency.coge}} {{agency.rs}}</option>
            </select>
            <mat-error>Champ obligatoire</mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Identifiant</mat-label>
            <input matInput name="identifiant" formControlName="identifiant" #identifiant required  autocomplete="off">
            <mat-icon matSuffix *ngIf="identifiant.value" (click)="identifiant.value=''">clear</mat-icon>
            <mat-error>Champ obligatoire</mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Mot de Passe</mat-label>
            <input type="password" matInput name="password" formControlName="password"
                [type]="hide ? 'password' : 'text'" #password required>
            <mat-icon matSuffix *ngIf="password.value" (click)="password.value=''">clear</mat-icon>
            <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error>Champ obligatoire</mat-error>
        </mat-form-field>
        <div fxLayout="row" fxLayoutAlign="center center">
            <button type="submit" mat-raised-button class="valider" [class.color]="signinForm.invalid"
                [disabled]="signinForm.invalid">Valider</button>
        </div>
    </form>
</mat-card>
</div>
</shared-layout>

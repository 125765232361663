import { Injectable } from '@angular/core';
import { ClientService } from '@app/services/client.service';
import { InterimaireService } from '@app/services/interimaire.service';

@Injectable({
  providedIn: 'root'
})
export class DialogActionsService {

  constructor(private clientService: ClientService, private interimaireService: InterimaireService) { }

  action(modalData: any) {

    switch (modalData.name) {
      case "deleteClient" :
            this.clientService.deleteClient(modalData.idUser, modalData.cetatu,modalData.component);
        break;
      case "deleteCommercial":
        this.clientService.deleteCommercialAssociatedWithOrder(modalData.id, modalData.component);
        break;
      case "openClientCompteInAdminMode":
        this.clientService.openUserCompteInAdminMode(modalData.idUser)
          .subscribe((res: any) => {
            if(res && ("url" in res)){
              //open a new tab with url connexion
              window.open(res.url);
            }
          });
        break;
      case "deleteInterimaire":
            this.interimaireService.deleteInterimaire(modalData.idUser, modalData.component);
        break;
      case "openInterimaireCompteInAdminMode":
        this.interimaireService.openUserCompteInAdminMode(modalData.idUser)
          .subscribe((res: any) => {
            if(res && ("url" in res)){
              //open a new tab with url connexion
              window.open(res.url);
            }
          });
        break;
      case "deleteMessageClient":
        this.clientService.deleteMessage(modalData.idMessage, modalData.component);
      case "deleteMessageInterimaire":
        this.interimaireService.deleteMessage(modalData.idMessage, modalData.component);
        default:
        break;

    }
  }


}

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AllInterimairesListItem, Interimaire } from '@app/shared/models/interimaire-model';
import { InterimaireService } from '@app/services/interimaire.service';
import { PageChangeEvent,RowClassArgs } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { finalize } from 'rxjs/operators';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '@app/shared/components/dialog/dialog.component';
import { UpdatePersonModel } from '@app/shared/models/person-model';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Shepher } from '@app/shared/models/shepher-model';
import { ShepherdsHelper } from '@app/helpers/shepherds.helper';
import { ShepherdInternServiceService } from '@app/services/shepherd-intern-service.service';
import {Location} from "@angular/common";
import {Router} from "@angular/router";

@Component({
  selector: 'app-interimaires',
  templateUrl: './interimaires.component.html',
  styleUrls: ['./interimaires.component.css']
})
export class InterimairesComponent implements OnInit, AfterViewInit {
  public gridView: any[];
  public pageSize = 100;
  public skip = 0;
  private items: AllInterimairesListItem[] = [];
  private totalLength: number;
  public multiple = true;
  public allowUnsort = true;
  public sort: SortDescriptor[] = [{field: 'nsala', dir: 'asc'}];
  dematForm: FormGroup;
  updateForm: FormGroup;
  updateData: UpdatePersonModel;
  seeFormDemat: boolean = false;
  seeFormUpdate: boolean = false;
  updateEmail: boolean;
  updateLogin: boolean;
  public etats: any[];
  public demats: any[];
  defaultValueEtats: string = 'Tous';
  interimaireName: string = '';
  interimaireNsala: string = '';
  interimaireStatut: string = '';
  stepsArray: Shepher[];
  public loading: boolean;

  constructor(private interimaireService: InterimaireService,
    public matDialog: MatDialog,
    private fb: FormBuilder,
    private shepherdInternService: ShepherdInternServiceService,
    private router: Router,
    private location: Location) { }

  ngOnInit(): void {
    this.loading = true;
    this.getAllInterimaires();
  }

  ngAfterViewInit(): void {
    ShepherdsHelper.setCurrentComponent(this);
    this.shepherdInternService.removeStep();
    if (ShepherdsHelper.getState() === true) {
      this.loadHelpInline();
    }
  }

  loadHelpInline() {
    this.stepsArray = [];
    this.stepsArray.push(new Shepher('creerAccesIsi', '.shepherd-button-12'
      , ["Cet onglet permet de créer un accès intérimaire."], true, true));

    this.stepsArray.push(new Shepher('isi', '.shepherd-button-11'
      , ["Cet onglet contient un tableau des accès intérimaire."], false, true));

    this.stepsArray.push(new Shepher('envoyerMessageIsi', '.shepherd-button-13'
      , ["Cet onglet permet d'envoyer un message  qui s'affichera dans le portail ISI d'un intérimaire ."]
      , true, true));
    this.stepsArray.push(new Shepher('envoyerMessageIsi', '.shepherd-button-msg-envoyes-interimaire'
      , ["Cet onglet contient les messages qui s'affichent dans le portail ISI des intérimaires."]
      , true, true));
      this.stepsArray.push(new Shepher('historiqueMessagesInterimaire', '.shepherd-button-14'
      , ["Cet onglet contient l'historique des activations d'accès des comptes intérimaires."]
        , true, true));

    this.stepsArray.push(new Shepher('rechercheIsi', '.shepherd-button-111'
      , ['Recherchez un intérimaire par son nom.'], true, true));
    this.stepsArray.push(new Shepher('isiTable', '.shepherd-button-112'
      , ["Tableau des accès intérimaire. On peut filtrer sur l’état (Actif ou Inactif ou Tous), filtrer " +
      "sur Démat(O:oui, N:non ou Tous)"], true, true));
    this.shepherdInternService.createTourOfSteps(this.stepsArray);
  }

  getAllInterimaires() {
    this.interimaireService.getAllInterimaires(0, this.pageSize)
      .pipe(finalize(() => {
        this.loadItems();
      }))
      .subscribe((interimaires: Interimaire[]) => {
        interimaires.forEach(interimaire => {
          this.items.push({
            id_user: interimaire['id_user'],
            coge: interimaire['coge'],
            contact: interimaire['contact'],
            login: interimaire['login'],
            email: interimaire['email'],
            is_signataire: interimaire['is_signataire'],
            is_actif: interimaire['is_actif'] == 1 ? 'Actif' : 'Inactif',
            dat_created: interimaire['dat_created'],
            credentials: interimaire['credentials'],
            compte_en_lys: interimaire['compte_en_lys'],
            code_legalyspace: interimaire['code_legalyspace'],
            dactiv: interimaire['dactiv'],
            demmat: interimaire['demmat'],
            nsala: interimaire['nsala']
          })
        })
        this.totalLength = interimaires.length;
      });
  }

  private loadItems(): void {
    this.gridView = this.items;
    if(this.gridView.length > 0) {
      this.loading = false;
    }
    this.demats = this.items.map(item => item.demmat)
      .filter((value, index, self) => self.indexOf(value) === index);
    this.demats.push(this.defaultValueEtats);
    this.etats = this.items.map(item => item.is_actif)
      .filter((value, index, self) => self.indexOf(value) === index);
    this.etats.push(this.defaultValueEtats);

  }

  public rowCallback(context: RowClassArgs) {
    const isEven = context.index % 2 == 0;
    return {
      even: isEven,
      odd: !isEven
    };
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadItems();
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
  }

  onSearch(filter) {
    let searchString = filter.trim();
    let interimaires: AllInterimairesListItem[] = [];
    if (searchString.length > 0) {
      this.loading = true;
      this.interimaireService.searchInterimairesInIsi(searchString)
        .subscribe((res: any[]) => {
          res.forEach(interimaire => {
            interimaires.push({
              id_user: interimaire['id_user'],
              coge: interimaire['coge'],
              contact: interimaire['contact'],
              login: interimaire['login'],
              email: interimaire['email'],
              is_signataire: interimaire['is_signataire'],
              is_actif: interimaire['is_actif'] == 1 ? 'Actif' : 'Inactif',
              dat_created: interimaire['dat_created'],
              credentials: interimaire['credentials'],
              compte_en_lys: interimaire['compte_en_lys'],
              code_legalyspace: interimaire['code_legalyspace'],
              dactiv: interimaire['dactiv'],
              demmat: interimaire['demmat'],
              nsala: interimaire['nsala']
            })
          });
          this.loading = false;
          //allowe Kendo angular pager will reset back to the first page
          this.pageChange({skip:0, take:0});
          this.gridView = interimaires;
        });
    } else {
      this.getAllInterimaires();
    }
  }

  updateLoginUpdateEmail(etat: any, legalyspace: any) {
    /**
     * On ne peut pas modifier l'email d'un compte actif avec legalyspace, seul son identifiant est modifiable
     * on peut modifier l' identifiant et l' email d'un compte inactif et d'un compte actif sans legalyspace,
     */
    if (etat === "Inactif" || (etat === "Actif" && !legalyspace)) {
      this.updateLogin = true;
      this.updateEmail = true;
    } else if (etat === "Actif" && legalyspace) {
      this.updateLogin = true;
      this.updateEmail = false;
    } else {
      this.updateLogin = false;
      this.updateEmail = false;
    }
  }

  openFormDemat(dataItem) {
    this.interimaireName = dataItem.contact;
    this.interimaireNsala = dataItem.nsala;
    this.interimaireStatut = dataItem.is_actif;
    this.dematForm = this.fb.group({
      'statut_demat': [dataItem.demmat, Validators.required]
    });
    this.seeFormDemat = true;
    this.seeFormUpdate = false;
  }

  demat() {
    if (this.dematForm.valid && this.interimaireNsala && this.interimaireStatut === 'Actif') {
      this.interimaireService.setDemat(this.interimaireNsala, this.dematForm.value.statut_demat, this);
      this.seeFormDemat = false;
      this.refreshComponent();
    }
  }

  cancelDemat() {
    this.seeFormDemat = false;
  }

  onRenvoiMailActivationt(dataItem) {
    this.interimaireService.renvoiMailDactivationt(dataItem.id_user);
  }

  openFormUpdate(dataItem) {
    this.interimaireName = dataItem.contact;
    //afficher ou non le champ login  ou/et email
    this.updateLoginUpdateEmail(dataItem.is_actif, dataItem.code_legalyspace);

    this.updateForm = new FormGroup({
      login: new FormControl(dataItem.login, [Validators.required]),
      email: new FormControl(dataItem.email, [Validators.required, Validators
        .pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")]),
    });

    this.seeFormUpdate = true;
    this.seeFormDemat = false;
    this.updateData = new UpdatePersonModel(dataItem.id_user, dataItem.contact, dataItem.login, dataItem.email);
  }

  update() {
    if (this.updateForm.valid) {
      this.interimaireService.update(new UpdatePersonModel(this.updateData.id_user, this.interimaireName,
        this.updateLogin ? this.updateForm.value.login : this.updateData.login,
        this.updateEmail ? this.updateForm.value.email : this.updateData.email), this);
      this.refreshComponent();
    }
  }

  cancelUpdate() {

    this.seeFormUpdate = false;
  }

  onOpenTablette(dataItem) {
    this.interimaireService.connexionTablette(dataItem.id_user);
  }

  onDelete(dataItem) {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-component";
    dialogConfig.height = "200px";
    dialogConfig.width = "360px";
    dialogConfig.data = {
      name: "deleteInterimaire",
      title: "Demande de confirmation",
      description: "Etes-vous certain de vouloir supprimer cet interimaire?",
      actionButtonText: "Oui",
      idUser: dataItem.id_user,
      component: this,
    }
    this.matDialog.open(DialogComponent, dialogConfig);
  }

  onOpenUserCompteInAdminMode(dataItem) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;// The user can't close the dialog by clicking outside its body
    dialogConfig.id = "modal-component";
    dialogConfig.height = "250px";
    dialogConfig.width = "380px";
    dialogConfig.data = {
      name: "openInterimaireCompteInAdminMode",
      title: "Demande de confirmation",
      description: "Voulez vous accéder à ce compte ISI en mode administrateur?",
      description2:"En mode administrateur, seule la consultation des informations est possible, aucune modification ne peut être effectuée.",
      actionButtonText: "Oui",
      idUser: dataItem.id_user,
      component: this,
    }
    this.matDialog.open(DialogComponent, dialogConfig);
  }

  refreshComponent() {
    this.router.navigateByUrl("tableau-de-bord/refresh", {skipLocationChange: true}).then(() => {
      this.router.navigate([decodeURI(this.location.path())])
    });
  }
}

<mat-sidenav-container autosize>
  <mat-sidenav #sidenav [(mode)]="over" [(opened)]="opened" class="bottom-to-top">
    <div class="logoBox" *ngIf="(navigableModule !== 'clip') && (navigableModule !== 'isi')">
      <img src="assets/images/svg/ALPHASOFT.svg" class="responsive logo" alt="Logo">
    </div>
    <div class="logoBox" *ngIf="navigableModule === 'clip'">
      <img src="assets/images/svg/Clip.svg" class="responsive logo" alt="Image du logo">
    </div>
    <div class="logoBox" *ngIf="navigableModule === 'isi'">
      <img src="assets/images/svg/Logo_ISI.svg" class="responsive logo" alt="Image du logo">
    </div>
    <div class="centerText" *ngIf="userNameUser != null">
      <span class="userspan">Utilisateur : </span><span class="userNamespan"> {{userNameUser}}</span>
    </div>
    <div class="centerText" *ngIf="coge != null">
      <span class="userspan">Agence : </span>
      <mat-form-field>
        <select matNativeControl [(ngModel)]="coge" required (change) ="switchAgency()">
          <option *ngFor="let agency of agencies" [value]="agency.coge">{{agency.coge}} {{agency.rs}}</option>
        </select>
      </mat-form-field>
    </div>
    <ul class="navigation-items">
      <li [ngClass]="{'active': selectedItem == 0}">
        <button mat-button color="primary" (click)="getHome()">
          <mat-icon aria-hidden="false">home</mat-icon>Accueil
        </button>
      </li>
      <li [ngClass]="{'active': selectedItem == 3}" *ngIf="navigableModule === 'clip'">
        <button class="shepherd-button-02" mat-button color="primary" (click)="addNewClient()">
          <mat-icon aria-hidden="false">person_add</mat-icon> Créer un accès client
        </button>
      </li>
      <li class="shepherd-button-01" [ngClass]="{'active': selectedItem == 1}" *ngIf="navigableModule === 'clip'">
        <button mat-button color="primary" (click)="getClient()">
          <mat-icon aria-hidden="false">people_alt</mat-icon> Accès par clients
        </button>
      </li>
      <li class="shepherd-button-acces-contacts" [ngClass]="{'active': selectedItem == 9}" *ngIf="navigableModule === 'clip'">
        <button mat-button color="primary" (click)="getContact()">
          <mat-icon aria-hidden="false">people_alt</mat-icon> Accès par contacts
        </button>
      </li>

      <li [ngClass]="{'active': selectedItem == 5}" *ngIf="navigableModule === 'clip'">
        <button class="shepherd-button-03" mat-button color="primary" (click)="sendMessageCLIP()">
          <mat-icon aria-hidden="false">message</mat-icon> Envoyer un message
        </button>
      </li>
      <li [ngClass]="{'active': selectedItem == 12}" *ngIf="navigableModule === 'clip'">
        <button class="shepherd-button-Msg-envoyes-client" mat-button color="primary" (click)="getMessagesCLIP()">
          <mat-icon aria-hidden="false">email</mat-icon> Messages envoyés
        </button>
      </li>
      <li [ngClass]="{'active': selectedItem == 4}" *ngIf="navigableModule === 'isi'">
        <button class="shepherd-button-12" mat-button color="primary" (click)="addNewInterimaire()">
          <mat-icon aria-hidden="false">person_add</mat-icon> Créer un accès intérimaire
        </button>
      </li>
      <li [ngClass]="{'active': selectedItem == 2}" *ngIf="navigableModule === 'isi'">
        <button class="shepherd-button-11" mat-button color="primary" (click)="getInterimaire()">
          <mat-icon aria-hidden="false">people_alt</mat-icon> Accès intérimaires
        </button>
      </li>
      <li [ngClass]="{'active': selectedItem == 6}" *ngIf="navigableModule === 'isi'">
        <button class="shepherd-button-13" mat-button color="primary" (click)="sendMessageISI()">
          <mat-icon aria-hidden="false">message</mat-icon> Envoyer un message
        </button>
      </li>
      <li [ngClass]="{'active': selectedItem == 7}" *ngIf="navigableModule === 'clip'">
        <button class="shepherd-button-04b" mat-button color="primary" (click)="messageClipHistory()">
          <mat-icon aria-hidden="false">history_edu</mat-icon> Historique des activations d'accès
        </button>
      </li>
      <li [ngClass]="{'active': selectedItem == 11}" *ngIf="navigableModule === 'isi'">
        <button class="shepherd-button-Msg-envoyes-interimaire" mat-button color="primary" (click)="getMessagesISI()">
          <mat-icon aria-hidden="false">email</mat-icon> Messages envoyés
        </button>
      </li>
      <li [ngClass]="{'active': selectedItem == 8}" *ngIf="navigableModule === 'isi'">
        <button class="shepherd-button-14" mat-button color="primary" (click)="messageIsiHistory()">
          <mat-icon aria-hidden="false" class='red-icon' >history_edu</mat-icon> Historique des activations d'accès
        </button>
      </li>
      <li [ngClass]="{'active': selectedItem == 10}" *ngIf="navigableModule === 'ad'">
        <button class="shepherd-button-14" mat-button color="primary" (click)="getAd()">
          <mat-icon aria-hidden="false" class='red-icon' >history</mat-icon> Liste des annonces
        </button>
      </li>
      <div class="help">
        <mat-slide-toggle [(ngModel)]="helpInLineChecked" class="example-margin" (change)="changed()"> Activer l'aide en ligne
        </mat-slide-toggle>
      </div>
      <li>
        <button mat-button class="deconnexion" (click)="logout()">
          <mat-icon aria-hidden="false">settings_power</mat-icon> Déconnexion
        </button>
      </li>

    </ul>
  </mat-sidenav>
  <mat-sidenav-content role="main">
    <mat-toolbar>
      <button mat-icon-button (click)="sidenav.toggle()"><mat-icon>menu</mat-icon></button>
      <span class="title">PILOTE - Administration des portails CLIP ISI </span>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

import { Injectable } from '@angular/core';
import { Interimaire, InterimaireInPegase } from '@app/shared/models/interimaire-model';
import { Observable, Subject, throwError, of } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { WEBSERVICE_ADDR } from '@app/shared/constants';
import { map } from 'rxjs/operators';
import { UpdatePersonModel } from '@app/shared/models/person-model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CreateCompteIsiForm } from '@app/shared/models/create-compte-isi-form-model';
import { UserHelper } from '@app/helpers/user.helper';
import { Router } from '@angular/router';
import {InterimairesComponent} from '@components/dashboardscreen/isi/interimaires/interimaires.component';
import {MessagesIsiComponent} from '@components/dashboardscreen/isi/messages-isi/messages-isi.component';

@Injectable({
  providedIn: 'root'
})
export class InterimaireService {
  public openInterimaireViewEvent = new Subject<boolean>();
  private END_POINT: string = 'isi/';
  contentTypeUrlencoded = 'application/x-www-form-urlencoded';
  contentTypeJson = 'application/json';

  constructor(private http: HttpClient, private snackBar: MatSnackBar, private router: Router) {}

  getHeader(contentType: string) {
    return new HttpHeaders({
      'Content-Type': contentType,
      'Authorization': `Bearer ${UserHelper.getUser().token}`
    });
  }

  getAllInterimaires(offset: number, limit: number): Observable<Interimaire[]> {
    const headers = this.getHeader(this.contentTypeUrlencoded);
    const options = { headers };
    const url = WEBSERVICE_ADDR + this.END_POINT + 'get-all/' + UserHelper.getUser().coge;

    return this.http.get<Interimaire[]>(url, options);
  }

  getAllInterimairesPaginated(offset: number, limit: number) {
    const headers = this.getHeader(this.contentTypeUrlencoded);
    const options = { headers };
    const url = WEBSERVICE_ADDR + this.END_POINT + 'get-all-paginated/' + UserHelper.getUser().coge + '/' + offset + '/' + limit;

    return this.http.get(url, options)
      .pipe(
        map((response: { length: number, interimaires: any[] }) => {
          return response;
        })
      );
  }

  searchInterimairesInIsi(searchString: string): Observable<Interimaire[]> {
    const headers = this.getHeader(this.contentTypeUrlencoded);
    const options = { headers };
    const url = WEBSERVICE_ADDR + this.END_POINT + 'search/' + UserHelper.getUser().coge + '/' + searchString;

    if (!searchString.trim()) {
      return of([]);
    }
    return this.http.get<Interimaire[]>(url, options);

  }

  searchInterimairesInPegase(searchString: string): Observable<InterimaireInPegase[]> {
    const headers = this.getHeader(this.contentTypeUrlencoded);
    const options = { headers };
    const url = WEBSERVICE_ADDR + 'interimaire/search/' + UserHelper.getUser().coge + '/' + searchString;

    if (searchString.length <= 0) {
      return of([]);
    }
    return this.http.get<InterimaireInPegase[]>(url, options);

  }

  renvoiMailDactivationt(idUser: string) {
    const headers = this.getHeader(this.contentTypeJson);
    const body = JSON.stringify({ "id_user": idUser });
    const url = WEBSERVICE_ADDR + this.END_POINT + 'reinit'

    return this.http.put(url, body, { headers: headers, observe: "response" })
      .subscribe(response => {
        if (response.status === 204) {
          this.snackBar.open('Mail de réinitialisé envoyé', null, { duration: 3000 });
        }
      },
        error => {
          this.snackBar.open(error.error.message, null, { duration: 3000 });
        }
      );
  }

  update(data: UpdatePersonModel, component: InterimairesComponent) {
    const headers = this.getHeader(this.contentTypeJson);
    const body = JSON.stringify(data);
    const url = WEBSERVICE_ADDR + this.END_POINT + 'update';

    return this.http.put(url, body, { headers: headers, observe: "response" })
      .subscribe(response => {
        if (response.status === 204) {
          this.snackBar.open('Mise à jour avec succès', null, { duration: 3000 });
          setTimeout(() => { component.refreshComponent();; }, 0);
        }
      },
        error => {
          this.snackBar.open('Mise à jour impossible', null, { duration: 3000 });
        }
      );
  }

  createAccesIsiCompte(data: CreateCompteIsiForm) {
    const headers = this.getHeader(this.contentTypeJson);
    const body = JSON.stringify(data);
    const url = WEBSERVICE_ADDR + this.END_POINT + 'create';

    this.http.post(url, body, { headers: headers, observe: "response" })
      .subscribe(response => {
        if (response.status === 201) {
          this.snackBar.open("Compte Isi créé avec succès", null, { duration: 4000 });
        }
      },
        error => {
         // console.log(error);
        }
      );
  }

  getMails(page: number, recipients: string) {
    const headers = this.getHeader(this.contentTypeJson);
    const url = `${WEBSERVICE_ADDR}${this.END_POINT}emails?page=${page}&recipients=${recipients}&order[id]=DESC`;
    return this.http.get(url,{headers: headers, observe:"response"});
  }

  setDemat(nsala: string, statut_demat: string, component: InterimairesComponent) {
    const headers = this.getHeader(this.contentTypeJson);
    const body = JSON.stringify({ "coge": UserHelper.getUser().coge, "nsala": nsala, "statut_demat": statut_demat });
    const url = WEBSERVICE_ADDR + '/interimaire/set-demat';
    return this.http.put(url, body, { headers, observe: "response" })
      .subscribe(response => {
        if (response.status === 204) {
          this.snackBar.open('Mise à jour avec succès', null, { duration: 3000 });
          setTimeout(() => { component.refreshComponent();; }, 0);

        }
      },
        error => {
          this.snackBar.open('Mise à jour impossible', null, { duration: 3000 });
        }
      );
  }

  deleteInterimaire(idUser: number, component: InterimairesComponent) {
    const headers = this.getHeader(this.contentTypeJson);
    const body = JSON.stringify({ "id_user": idUser });
    const options = { headers, body };
    const url = WEBSERVICE_ADDR + this.END_POINT + 'delete';

    this.http.delete(url, options)
      .subscribe(response => {
        this.snackBar.open('Ce compte a été suprimé', null, { duration: 3000 });
        setTimeout(() => {
          component.refreshComponent();; }, 0);
      },
        error => {
          console.log(error)
        });
  }

  connexionTablette(idUser: string) {
    const headers = this.getHeader(this.contentTypeJson);
    const body = JSON.stringify({ "coge": UserHelper.getUser().coge, "id_user": idUser });
    const url = WEBSERVICE_ADDR + this.END_POINT + 'set-connexion-agence';

    return this.http.put(url, body, { headers: headers, observe: "response" })
      .subscribe((response) => {
        if (response.status === 204) {
          this.snackBar.open('Connexion agence établie avec succès', null, { duration: 3000 });
        }
      },
        error => {
          this.snackBar.open('Connexion impossible', null, { duration: 3000 });
        });
  }

  sendMessageISI(data: object) {
    const headers = this.getHeader(this.contentTypeJson);
    const url = `${WEBSERVICE_ADDR}/interimaire/send-message`;
    this.http.post(url, data, { headers: headers, observe: "response" })
      .subscribe(response => {
          if (response.status === 201) {
            this.snackBar.open("Message envoyé avec succès", null, { duration: 4000 });
          }
        },
        error => {
          this.snackBar.open('Connexion impossible', null, { duration: 3000 });
        }
      );
  }

  openUserCompteInAdminMode(idUser: number){
    const headers = this.getHeader(this.contentTypeUrlencoded);
    const options = { headers };
    const url = `${WEBSERVICE_ADDR}${this.END_POINT}user-token/${idUser}`;

    return this.http.get<any>(url, options);
  }

  // tslint:disable-next-line:typedef
  getMessages() {
    const headers = this.getHeader(this.contentTypeUrlencoded);
    const options = { headers };
    const url = WEBSERVICE_ADDR + 'messages/interimaire';

    return this.http.get<[any]>(url, options);
  }

  // tslint:disable-next-line:typedef
  deleteMessage(idMessage: number, component: MessagesIsiComponent) {
    const headers = this.getHeader(this.contentTypeJson);
    const options = { headers };
    const url = WEBSERVICE_ADDR + 'messages/' + idMessage;
    this.http.delete(url, options)
      .subscribe(response => {
          this.snackBar.open('Ce message a été suprimé', null, { duration: 3000 });
          setTimeout(() => {
            component.refreshComponent(); }, 0);
        },
        error => {
          console.log(error);
        }
      );
  }
}

import {Component, OnInit} from '@angular/core';
import {GridDataResult, PageChangeEvent} from '@progress/kendo-angular-grid';
import {finalize} from 'rxjs/operators';
import {AdModel} from "@shared/models/ad-model";
import {AdService} from "@app/services/ad.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'app-ad',
  templateUrl: './ad.component.html',
  styleUrls: ['./ad.component.css']
})
export class AdComponent implements OnInit {

  public gridView: GridDataResult;
  private totalLength: number;
  public pageSize = 100;
  public skip = 0;
  private items: any[] = [];
  private dataSource:MatTableDataSource<any> ;
  filter: string = "";
  page: number;

  constructor(private adService: AdService, private router: Router) {
  }

  ngOnInit(): void {
    this.getAds();
  }

  getAds() {
    this.adService.getAds()
      .pipe(finalize(() => {
        this.loadItems();
      }))
      .subscribe(response => {
          this.items = [];
          response.forEach(ad => {
            this.items.push({
              id_annonce : ad.id_annonce,
              reference : ad.reference,
              titre : ad.titre,
              actif : ad.actif  == 1 ? 'Actif' : 'Inactif',
              date_creation : ad.date_creation
            })
          });

        this.dataSource = new MatTableDataSource(this.items);

          this.totalLength = response.length;
        }
      );
  }


  // private loadItems(): void {
  //   this.gridView = this.items;
  // }

  private loadItems(): void {
    this.gridView = {
      data: this.items.slice(this.skip, this.skip + this.pageSize),
      total: this.items.length,
    };
  }


  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems();
  }

  // public pageChange(event: PageChangeEvent): void {
  //   this.getAds();
  // }

  public onFilter(inputValue: string): void {
    this.dataSource.filter = inputValue;
    this.items = this.dataSource.filteredData;
    this.loadItems();
  }

  public openFormUpdate(id:string) {
    this.router.navigate([`tableau-de-bord/ad/update/${id}`])
  }
}

<div class="mainBox" fxLayout="column">
  <div class="titleBox">
    <span class="titleA">Déstinataires de l'agence des alertes CLIP ou ISI par mail </span>
  </div>
  <!--Table des commerciaux -->
  <div class="contentBox" fxLayout="row" fxLayout.md="column" fxLayoutAlign.md="start center"  fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutAlign="start start" fxLayoutAlign.sm="start center" fxLayoutAlign.xs="start center">
    <div class="leftDiv">
      <mat-card  class="mat-elevation-z0">
      <form [formGroup]="commercialForm" class="form" fxLayout="column" fxLayoutAlign="space-between center"
              fxLayoutGap="15px"
              (ngSubmit)="createManager()" *ngIf="seeFormUpdate === false">
          <span class="title">Créer un destinataires mails CLIP/ISI </span>
          <mat-form-field appearance="fill">
            <mat-label>Commerciale</mat-label>
            <select matNativeControl name="commercial" [(ngModel)]="commercial" required
                    (change)="managersMailSelected()" formControlName="commercial">
              <option *ngFor="let commercial of commercials" [ngValue]="commercial">{{commercial.name}}</option>
            </select>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Nom</mat-label>
            <input matInput name="name" formControlName="name" [(ngModel)]="commercialForm.value.name" required>
            <mat-error *ngIf="commercialForm.controls.name.hasError('required')">Champ obligatoire</mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Code Commerciale</mat-label>
            <input matInput name="ccomer" formControlName="ccomer" [(ngModel)]="commercialForm.value.ccomer" required>
            <mat-error *ngIf="commercialForm.controls.ccomer.hasError('required')">Champ obligatoire</mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Portable</mat-label>
            <input class="shepherd-button-033" matInput name="portable" formControlName="portable"/>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Adresse mail</mat-label>
            <input class="shepherd-button-033" matInput name="email" formControlName="email" required
                   pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"/>
            <mat-error *ngIf="commercialForm.controls.email.hasError('required')">Champ obligatoire</mat-error>
            <mat-error *ngIf="commercialForm.controls.email.hasError('pattern')">Email invalide</mat-error>
          </mat-form-field>
          <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="15px">
            <button mat-button type="submit" class="valider">Envoyer</button>
            <button mat-button (click)="cancel()"> Annuler</button>
          </div>
        </form>
        <!--<mat-card > *ngIf="seeFormUpdate === true"-->
        <form [formGroup]="updateCommercialForm" class="form" fxLayout="column" fxLayoutAlign="space-between center"
              fxLayoutGap="15px"
              (ngSubmit)="updateCommercial()" *ngIf="seeFormUpdate === true">
          <span class="title">Mettre à jour ce destinataire mails CLIP/ISI </span>
          <mat-form-field appearance="fill">
            <mat-label>Email</mat-label>
            <input matInput name="email" formControlName="email" #email required
                   pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
            <mat-error *ngIf="updateCommercialForm.controls.email.hasError('required')">Champ obligatoire</mat-error>
            <mat-error *ngIf="updateCommercialForm.controls.email.hasError('pattern')">Email invalide</mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Portable</mat-label>
            <input matInput name="portable" formControlName="portable" #portable required>
            <mat-error>Champ obligatoire</mat-error>
          </mat-form-field>
          <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="15px">
            <button mat-button type="submit" class="valider">Envoyer</button>
            <button mat-button (click)="cancelUpdate()"> Annuler</button>
          </div>
        </form>
      </mat-card>

    </div>
    <div class="rigthDiv">
      <kendo-grid [data]="gridView"
                  [selectable]="{enabled: true,checkboxOnly: true, mode: 'single'}"
                  [resizable]="true"
                  [pageSize]="pageSize"
                  [skip]="skip"
                  [sort]="sort"
                  [sortable]="{ allowUnsort: true,  mode: 'single'  }"
                  [pageable]="true"
                  [style.width]="'100%'"
                  [style.height]="'100%'"
                  (sortChange)="sortChange($event)"
                  (pageChange)="pageChange($event)">
        <kendo-grid-command-column title="Action" width="110" [headerClass]="'tableHeader'" [resizable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            <!--Modifier client-->
            <button mat-icon-button aria-label="update" class='violet-icon' matTooltip="Mettre à jour"
                    (click)="onUpdate(dataItem)">
              <mat-icon>edit</mat-icon>
            </button>
            <!-- Supprimer un commerciale-->
            <button mat-icon-button aria-label="delete" class='red-icon' matTooltip="Supprimer" color="primary"
                    (click)="onDelete(dataItem)">
              <mat-icon> delete</mat-icon>
            </button>
          </ng-template>
        </kendo-grid-command-column>
        <kendo-grid-column field="nom" title="Nom" [headerClass]="'tableHeader'" width="220"></kendo-grid-column>
        <kendo-grid-column field="email" title="Email" [headerClass]="'tableHeader'" width="220"></kendo-grid-column>
        <kendo-grid-column field="tel" title="Téléphone" [headerClass]="'tableHeader'" width="120"></kendo-grid-column>
        <kendo-grid-column field="portable" title="Portable" [headerClass]="'tableHeader'"
                           width="110"></kendo-grid-column>
        <kendo-grid-column field="fax" title="Fax" [headerClass]="'tableHeader'" width="100"></kendo-grid-column>
        <kendo-grid-messages groupPanelEmpty="Drag here to group" noRecords="Aucun commerciaux chargés des commandes">
        </kendo-grid-messages>
      </kendo-grid>
    </div>
  </div>
</div>

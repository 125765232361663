<div fxLayout="column wrap" flexfill>
  <div fxFlex="10%" class="searchBox">
    <div fxLayout="row wrap" flexfill>
      <div class="searchDiv" fxLayout="row wrap" flexfill>
        <label [for]="'input'">Rechercher un intérimaire :</label>
        <input class="k-textbox shepherd-button-131" (input)="onSearch($event.target.value)"
          placeholder="Effectuez la recherche par son nom" />
      </div>
    </div>
  </div>
  <!--Formulaire de creation de d'accès clip-->
  <div fxFlex="10%" class="createIsiClipBox" *ngIf="seeForm === true">
    <mat-card>
      <span class="title">Envoyer un message à l'intérimaire {{interimaireName}}</span>
      <form [formGroup]="messageForm" fxLayout="column wrap" fxLayoutAlign="" fxLayoutGap="15px"
        (ngSubmit)="sendMessage()">

        <mat-grid-list cols="2" rowHeight="70px">
          <mat-grid-tile>
            <mat-form-field appearance="fill">
              <mat-label>Date d'action</mat-label>
              <input type="date" matInput name="start_date" formControlName="start_date" [(ngModel)]="messageForm.value.start_date" placeholder="01/12/2021">
              <mat-error *ngIf="messageForm.controls.start_date.hasError('required')">Champ obligatoire</mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="fill">
              <mat-label>Date de fin</mat-label>
              <input type="date" matInput name="end_date" formControlName="end_date" [(ngModel)]="messageForm.value.end_date" placeholder="01/12/2021">
              <mat-error *ngIf="messageForm.controls.end_date.hasError('required')">Champ obligatoire</mat-error>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>

        <mat-form-field appearance="fill">
          <mat-label>Sujet :</mat-label>
          <input class="shepherd-button-133" matInput name="subject" formControlName="subject" required maxlength="25"/>
          <mat-error *ngIf="messageForm.controls.subject.hasError('required')">Champ obligatoire</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Message</mat-label>
          <textarea matInput name="message" formControlName="message" #message required maxlength="600"
            style="height:80px;"></textarea>
          <label class="nopadding clrlbl"> 600 caractères maximum: {{messageForm.value?.message?.length}} /600</label>
          <mat-error *ngIf="messageForm.controls.message.hasError('required')">Champ obligatoire</mat-error>
        </mat-form-field>
        <div fxLayout="row wrap" fxLayoutAlign="center" fxLayoutGap="15px">
          <button mat-button type="submit" class="valider">Envoyer</button>
          <button mat-button (click)="cancel()"> Annuler</button>
        </div>
      </form>
    </mat-card>
  </div>
  <div class="loader" *ngIf="loading === true"></div>
  <!--Table des interimaires -->
  <div fxFlex="70%" id="tableBox" class="tableContentBox" *ngIf="seeTable">
    <kendo-grid
      [data]="gridView"
      [selectable]="{enabled: true,checkboxOnly: true, mode: 'single'}"
      [resizable]="true"
      [pageSize]="pageSize"
      [skip]="skip"
      [pageable]="true"
      [style.height.px]="600"
      (selectionChange)="selectionChange($event)">
      <kendo-grid-checkbox-column  class="shepherd-button-132" width="50" [headerClass]="'tableHeader'"></kendo-grid-checkbox-column>
      <kendo-grid-column field="nsala" title="Numéro" [headerClass]="'tableHeader'"></kendo-grid-column>
      <kendo-grid-column field="contact" title="Nom de l'intérimaire" [headerClass]="'tableHeader'"></kendo-grid-column>
      <kendo-grid-column field="login" title="Identifiant" [filterable]="false" [headerClass]="'tableHeader'">
      </kendo-grid-column>
      <kendo-grid-column field="email" title="Email" [filterable]="false" [headerClass]="'tableHeader'">
      </kendo-grid-column>
      <kendo-grid-column field="is_actif" title="Etat" [filterable]="false" [headerClass]="'tableHeader'">
      </kendo-grid-column>
      <kendo-grid-messages groupPanelEmpty="Drag here to group" noRecords="Aucun intérimaire trouvé">
      </kendo-grid-messages>
      <kendo-grid-messages
        [pagerItemsPerPage]="'custom items per page text'"
        [pagerItems]="''"
        [pagerOf]="'sur'"
        [pagerPage]="'custom Page text'"
      >
      </kendo-grid-messages>
    </kendo-grid>
  </div>

</div>

export class CreateCompteIsiForm {
    coge: string;
    nsala: string;
    email: string;
    contact: string;
    is_signataire: boolean;
    
    constructor( coge: string, nsala: string, email: string, contact: string, is_signataire: boolean) {
        this.coge = coge;
        this.nsala = nsala;
        this.email = email;
        this.contact = contact;
        this.is_signataire = is_signataire;
    }
}
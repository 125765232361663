import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '@app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService,
        private snackBar: MatSnackBar, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(catchError(err => {
            if (err instanceof HttpErrorResponse) {
                if (err.error instanceof ErrorEvent) {
                    console.error("Pilote: Error Event");
                } else {
                    // auto logout if 401 or 403 response returned from api
                    if (err.status === 401) {
                        if(err.error.message.includes('token')) {
                            this.snackBar.open("Votre session a expiré, veuillez vous reconecter", null, { duration: 4000 });

                        } else {
                            this.snackBar.open(err.error.message, null, { duration: 4000 });
                        }
                        this.authenticationService.logout();
                    } else if(err.status === 403){
                        this.snackBar.open("Accès interdit", null, { duration: 4000 });
                        this.authenticationService.logout();

                    }else if (err.status === 400 || err.status === 404) {
                        this.snackBar.open(err.error.message, null, { duration: 4000 });
                    }else {
                        return throwError("Une erreur s'est produite, veuillez réessayer plus tard " + err.error.message);
                    }
                }

            } else {
                // The backend returned an unsuccessful response code.
                // The response body may contain clues as to what went wrong.
                console.error(
                    `Pilote: Backend returned code ${err.status}, ` +
                    `body was: ${err.error}`);
            }


        }))
    }
}

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ShepherdsHelper } from '@app/helpers/shepherds.helper';
import { UserHelper } from '@app/helpers/user.helper';
import { InterimaireService } from '@app/services/interimaire.service';
import { ShepherdInternServiceService } from '@app/services/shepherd-intern-service.service';
import { AllInterimairesListItem, InterimaireInPegase } from '@app/shared/models/interimaire-model';
import { Shepher } from '@app/shared/models/shepher-model';
import {GridDataResult, PageChangeEvent, SelectableSettings} from '@progress/kendo-angular-grid';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'app-send-message-to-isi-compte',
  templateUrl: './send-message-to-isi-compte.component.html',
  styleUrls: ['./send-message-to-isi-compte.component.css']
})
export class SendMessageToIsiCompteComponent implements OnInit, AfterViewInit {
  public gridView: GridDataResult;
  public pageSize = 100;
  items: any[] = [];
  public skip = 0;
  public coge: string;
  public sort: SortDescriptor[] = [{ field: 'nom', dir: 'asc' }];
  seeTable: boolean = false;
  seeForm: boolean = false;
  interimaire: InterimaireInPegase;
  interimaireName: string= '';
  messageForm: FormGroup;
  public selectableSettings: SelectableSettings;
  stepsArray: Shepher[];
  public loading: boolean;

  constructor(
    private interimaireService: InterimaireService,
    private shepherdInternService: ShepherdInternServiceService) {

    this.selectableSettings = {
      checkboxOnly: false,
      mode: 'multiple',
      drag: false
    };
  }

  ngOnInit(): void {
    this.coge = UserHelper.getUser().coge;
  }

  ngAfterViewInit(): void {
    ShepherdsHelper.setCurrentComponent(this);
    this.shepherdInternService.removeStep();
    if (ShepherdsHelper.getState() === true) {
      this.loadHelpInline();
    }
  }

  loadHelpInline() {
    this.stepsArray = [];
    //creer le  popup de la zone de recherche
    this.stepsArray.push(new Shepher('isiRechercheInPegaseMessage', '.shepherd-button-131'
      , ['Recherchez un intérimaire dans PEGASE par son nom.'], false, true));
    this.shepherdInternService.createTourOfSteps(this.stepsArray);
  }

  onSearch(filter) {
    if (filter.trim()) {
      this.loading = true;
      let interimaires: AllInterimairesListItem[] = [];
      this.interimaireService.searchInterimairesInIsi(filter.trim())
        .subscribe((res: any[]) => {
          if(res) {
            this.pageSize = res.length;
            res.forEach(interimaire => {
              if(interimaire['is_actif'] == 1) {
                interimaires.push({
                  id_user: interimaire['id_user'],
                  coge: interimaire['coge'],
                  contact: interimaire['contact'],
                  login: interimaire['login'],
                  email: interimaire['email'],
                  is_signataire: interimaire['is_signataire'],
                  is_actif: interimaire['is_actif'] == 1 ? 'Actif' : 'Inactif',
                  dat_created: interimaire['dat_created'],
                  credentials: interimaire['credentials'],
                  compte_en_lys: interimaire['compte_en_lys'],
                  code_legalyspace: interimaire['code_legalyspace'],
                  dactiv: interimaire['dactiv'],
                  demmat: interimaire['demmat'],
                  nsala: interimaire['nsala']
                });

              }

            });
            this.gridView = {
              data: orderBy(interimaires, this.sort),
              total: interimaires.length
            };
            this.loading = false;
          }

          if (!this.seeTable) {
            this.seeTable = true;
            if (ShepherdsHelper.getState() === true) {
              //fermer un pop ouvert si un popup est ouvert avant d'ouvrir un nouveau
              this.shepherdInternService.hideStep();
              this.stepsArray.push(new Shepher('isiCreateTableMessage', '.shepherd-button-132',
                ["Tableau des interimaires dont le nom contient l'expression rechercher. Selectioner un interimaire en cliquant sur la case à cocher afin d'ouvrir le formulaire d'envoie de message."], true, true));
              this.shepherdInternService.createSteps(this.stepsArray);
              this.shepherdInternService.getShepherdService().show('isiCreateTableMessage');
            }
          }
        });
    } else {
      this.seeTable = false
    }
  }

  public selectionChange(e) {
    if (e.selectedRows[0]) {
      this.interimaire = e.selectedRows[0].dataItem;
      this.interimaireName = this.interimaire.contact !== null? this.interimaire.contact:'';
      this.messageForm = new FormGroup({
        to: new FormControl({ value: this.interimaire.nsala, disabled: true }, Validators.required),
        start_date: new FormControl(null, Validators.required),
        end_date: new FormControl(null, Validators.required),
        subject: new FormControl(null, [Validators.required, Validators.maxLength(25)]),
        message: new FormControl(null, [Validators.required, Validators.maxLength(600)])
      });
      if (!this.seeForm) {
        this.seeForm = true;
        if (ShepherdsHelper.getState() === true) {
          //fermer un pop ouvert si un popup est ouvert avant d'ouvrir un nouveau
          this.shepherdInternService.hideStep();
          this.stepsArray.push(new Shepher('isiCreateFormMessage', '.shepherd-button-133',
            ["Ce formulaire permet d’envoyer un message qui s’affichera dans le portail ISI d’un intérimaire. Le champs « À » est prérempli"
            +" par le « Numéro » de l’intérimaire. Le champ « Sujet » ne peut contenir que 25 caractères. Le champ « Message » ne peut contenir que 600 caractères."], true, false, true));
          this.shepherdInternService.createSteps(this.stepsArray);
          this.shepherdInternService.getShepherdService().show('isiCreateFormMessage');
        }
      }
    } else {
      this.seeForm = false;
    }
  }

  sendMessage() {
    if (this.messageForm.status === 'VALID') {
      this.interimaireService.sendMessageISI(this.messageForm.getRawValue());
      this.seeForm = false;
    }
  }

  cancel() {
    this.seeForm = false;
  }

}

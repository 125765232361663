import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '@app/services/authentication.service';
import { UserHelper } from './user.helper';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private authenticationService: AuthenticationService ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, ) {

        const user = UserHelper.getUser();
        if (user && user.token) {
            // logged in so return true
            return true;
        } else {
            // not logged in so redirect to login page with the return url
            this.authenticationService.logout()
            return false;
        }
    }
}

import { AfterViewInit, Component, OnInit } from '@angular/core';
import {GridDataResult, PageChangeEvent} from '@progress/kendo-angular-grid';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { InterimaireService } from '@app/services/interimaire.service';
import { InterimaireInPegase } from '@app/shared/models/interimaire-model';
import { CreateCompteIsiForm } from '@app/shared/models/create-compte-isi-form-model';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { UserHelper } from '@app/helpers/user.helper';
import { Shepher } from '@app/shared/models/shepher-model';
import { ShepherdsHelper } from '@app/helpers/shepherds.helper';
import { ShepherdInternServiceService } from '@app/services/shepherd-intern-service.service';

@Component({
  selector: 'app-add-new-interimaire',
  templateUrl: './add-new-interimaire.component.html',
  styleUrls: ['./add-new-interimaire.component.css']
})
export class AddNewInterimaireComponent implements OnInit, AfterViewInit {
  public gridView: GridDataResult;
  public pageSize = 100;
  public skip = 0;
  items: any[] = [];
  public coge: string;
  public sort: SortDescriptor[] = [{ field: 'nom', dir: 'asc' }];
  seeTable: boolean = false;
  seeForm: boolean = false;
  interimaire: InterimaireInPegase;
  newInterimaireForm: FormGroup;
  stepsArray: Shepher[];
  public loading: boolean;

  constructor(
    private interimaireService: InterimaireService,
    private shepherdInternService: ShepherdInternServiceService) { }

  ngOnInit(): void {
    this.coge = UserHelper.getUser().coge;
  }

  ngAfterViewInit(): void {
    ShepherdsHelper.setCurrentComponent(this);
    this.shepherdInternService.removeStep();
    if (ShepherdsHelper.getState() === true) {
      this.loadHelpInline();
    }
  }

  /**
   * Permet d'afficher laide en ligne
   */
  loadHelpInline() {
    this.stepsArray = [];
    this.stepsArray.push(new Shepher('isiRechercheInPegase', '.shepherd-button-121'
      , ['Recherchez un intérimaire dans PEGASE par son numéro interimaire ou par son nom.'], false, true));
    this.shepherdInternService.createTourOfSteps(this.stepsArray);
  }

  getContact(name: string, lastName: string) {
    if (name && lastName) {
      return name + " " + lastName;
    } else if (name && !lastName) {
      return name;
    } else if (!name && lastName) {
      return lastName;
    }
    return "";
  }

  public selectionChange(e) {
    if (e.selectedRows[0]) {
      this.interimaire = e.selectedRows[0].dataItem;
      let contact = this.getContact(this.interimaire.nom, this.interimaire.prenom);
      this.newInterimaireForm = new FormGroup({
        contact: new FormControl(contact, Validators.required),
        email: new FormControl(this.interimaire.email ? this.interimaire.email : "", [Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")]),
        is_signataire: new FormControl(true, Validators.required)
      });

      if (!this.seeForm) {
        this.seeForm = true;
        if (ShepherdsHelper.getState() === true) {
          //fermer un pop ouvert si un popup est ouvert avant d'ouvrir un nouveau
          this.shepherdInternService.hideStep();
          this.stepsArray.push(new Shepher('isiCreateForm', '.shepherd-button-123',
            ["Le formulaire de création d’accès. Le champs « Nom et prénom » et le champs « Email » sont préremplis. Le champ « Signature » par défaut est à « Oui »"], true, false, true));
          this.shepherdInternService.createSteps(this.stepsArray);
          this.shepherdInternService.getShepherdService().show('isiCreateForm');
        }
      }
    } else {
      this.seeForm = false;
    }
  }

  onSearch(filter) {
    if (filter.trim()) {
      this.loading = true;
      this.interimaireService.searchInterimairesInPegase(filter.trim())
        .subscribe((res: InterimaireInPegase[]) => {
          if(res) {
            this.pageSize = res.length;
            this.gridView = {
              data: orderBy(res, this.sort),
              total: res.length
            };
            this.loading = false;
          }

          if (!this.seeTable) {
            this.seeTable = true;
            if (ShepherdsHelper.getState() === true) {
              //fermer un pop ouvert si un popup est ouvert avant d'ouvrir un nouveau
              this.shepherdInternService.hideStep();
              this.stepsArray.push(new Shepher('isiCreateTable', '.shepherd-button-122',
                ["Tableau des interimaires dont le nom contient l'expression rechercher. Selectioner un interimaire en cliquant sur la case à cocher afin d'ouvrir le formulaire de création."], true, true));
              this.shepherdInternService.createSteps(this.stepsArray);
              this.shepherdInternService.getShepherdService().show('isiCreateTable');
            }
          }
        }
        );
    } else {
      this.seeForm = false;
      this.seeTable = false
    }
  }

  createAccesIsiCompte() {
    if (this.newInterimaireForm.valid) {
      this.interimaireService.createAccesIsiCompte(new CreateCompteIsiForm(this.coge, this.interimaire.nsala, this.newInterimaireForm.value.email,
        this.newInterimaireForm.value.contact, this.newInterimaireForm.value.is_signataire));
      this.seeForm = false;
    }
  }

  cancel() {
    this.seeForm = false;
  }

}

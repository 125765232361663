export class Shepher {
    id: string;
    element: string;
    text: string[];
    useBackButton: boolean;
    useNextButton: boolean;
    useExitButton?: boolean;
    constructor(id: string, element: string, text: string[], useBackButton: boolean, useNextButton: boolean, useExitButton?: boolean)  {
      this.id = id;
      this.element = element;
      this.text = text;
      this.useBackButton = useBackButton;
      this.useNextButton = useNextButton;
      this.useExitButton = useExitButton;
    }
}

<div fxLayout="column" fxFlexFill>
    <div class="header" fxFlex="11%">
        <shared-header></shared-header>
    </div>
    <div class="content" fxFlex="86%">
        <ng-content fxFlex="100%"></ng-content>
    </div>
    <div class="footer"  fxLayout="column" fxLayoutAlign="center center">
        <shared-footer></shared-footer>
    </div>
</div>

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShepherdsHelper } from '@app/helpers/shepherds.helper';
import { UserHelper } from '@app/helpers/user.helper';
import { ShepherdInternServiceService } from '@app/services/shepherd-intern-service.service';
import { Shepher } from '@app/shared/models/shepher-model';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {
  clipModule: boolean = false;
  isiModule: boolean = false;
  adModule: boolean = true;
  application: string[];
  stepsArray: Shepher[];

  constructor(
    private router: Router,
    private shepherdInternService: ShepherdInternServiceService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    sessionStorage.removeItem('module');
    let user = UserHelper.getUser();
    this.clipModule = ('CLIP' in user.applications && user.applications['CLIP'] === true);
    this.isiModule = ('ISI' in user.applications && user.applications['ISI'] === true);
  }

  ngAfterViewInit(): void {
    ShepherdsHelper.setCurrentComponent(this);
    this.shepherdInternService.removeStep();
    if ( ShepherdsHelper.getState() === true) {
      this.loadHelpInline();
    }
  }

  loadHelpInline() {
    this.stepsArray = []
        this.stepsArray.push(new Shepher('clip', '.shepherd-button-1'
          , ["Cliquer pour accèder à la gestion d'accès CLIP."], false, true));

        this.stepsArray.push(new Shepher('isi', '.shepherd-button-2'
          , ["Cliquer pour accèder à la gestion d'accès ISI."], true, true, false));
    this.stepsArray.push(new Shepher('pegase-mail', '.shepherd-button-pegase-mail'
      , ["Cliquer pour accèder aux déstinataires de l'agence des alertes clip ou isi par mail."], true, false, true));


    this.shepherdInternService.createTourOfSteps(this.stepsArray);
  }

  loadCLIPModule() {
    if (this.clipModule) {
      sessionStorage.setItem('module', 'clip');
      this.router.navigate(['tableau-de-bord/clip']);
    }
  }

  loadISIModule() {
    if (this.isiModule) {
      sessionStorage.setItem('module', 'isi');
      this.router.navigate(['tableau-de-bord/isi']);
    }
  }

  loadAdModule() {
    sessionStorage.setItem('module', 'ad');
    this.router.navigate(['tableau-de-bord/ad']);
  }

  loadClientOrderManagersPModule() {
    this.router.navigate(['tableau-de-bord/annuaires-des-destinataires-mails']);
  }
}

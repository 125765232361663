<div fxLayout="column wrap" flexfill>
  <div fxFlex="10%" class="searchBox">
    <div fxLayout="row wrap" flexfill>
      <div class="searchDiv">
        <label [for]="'input'">Rechercher un client dans PEGASE : </label>
        <input class="k-textbox  shepherd-button-021" (input)="onSearch($event.target.value)"
               placeholder="Effectuez la recherche par son code ou raison sociale"/>
      </div>
    </div>
  </div>

  <!--Formulaire de creation de d'accès clip-->
  <div fxFlex="100%" class="createIsiClipBox" *ngIf="seeForm === true">
    <mat-card class="shepherd-button-023">
      <span class="title"> Créer un accès client</span>
      <form [formGroup]="newClientForm" fxLayout="column" fxLayoutAlign="" fxLayoutGap="15px"
            (ngSubmit)="createCompteClip()">
        <mat-form-field>
          <mat-label>Contact</mat-label>
          <input matInput name="contact" formControlName="contact" #contact required>
          <mat-error>Champ obligatoire</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input matInput name="email" formControlName="email" #email required
                 pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
          <mat-error *ngIf="newClientForm.controls.email.hasError('required')">Champ obligatoire</mat-error>
          <mat-error *ngIf="newClientForm.controls.email.hasError('pattern')">Email invalide</mat-error>
        </mat-form-field>
        <mat-radio-group fxLoyaout="row" fxLayoutGap="5px" formControlName="is_signataire">
          <mat-label class="label-with-100">Signature :</mat-label>
          <mat-radio-button [value]=true name="true">Oui</mat-radio-button>
          <mat-radio-button [value]=false name="false">Non</mat-radio-button>
        </mat-radio-group>
        <mat-radio-group fxLoyaout="row" fxLayoutGap="5px" formControlName="is_invoicing">
          <mat-label class="label-with-100">Facturation :</mat-label>
          <mat-radio-button [value]=true name="true">Oui</mat-radio-button>
          <mat-radio-button [value]=false name="false">Non</mat-radio-button>
        </mat-radio-group>
        <button mat-button type="submit" class="valider">Valider</button>
        <button mat-button class="cancel" (click)="cancel()"> Annuler</button>
      </form>
    </mat-card>
  </div>

  <!--Table des contacts client -->
  <div fxFlex="10%" class="createIsiClipBox" *ngIf="seeForm === true">
    <kendo-grid [data]="gridViewContact"
                [selectable]="{checkboxOnly: true, mode: 'single'}"
                [resizable]="true"
                [pageSize]="pageSizeContact"
                (selectionChange)="selectionChangeContact($event)"
                [skip]="skip"
                [pageable]="true"
                [style.height.px]="300">
      <kendo-grid-checkbox-column width="50">
        <ng-template kendoGridCellTemplate let-idx="rowIndex" let-dataItem>
          <input [kendoGridSelectionCheckbox]="idx" [disabled]="!(dataItem.access_cetatu=== null)"/>
        </ng-template>
      </kendo-grid-checkbox-column>
      <kendo-grid-column field="nom" title="Contact" [headerClass]="'tableHeader'"></kendo-grid-column>
      <kendo-grid-column field="email" title="Email" [headerClass]="'tableHeader'"></kendo-grid-column>
      <kendo-grid-messages groupPanelEmpty="Drag here to group"
                           noRecords="Aucun contact de ce client trouvé"></kendo-grid-messages>
      <kendo-grid-messages
        [pagerItemsPerPage]="'custom items per page text'"
        [pagerItems]="''"
        [pagerOf]="'sur'"
        [pagerPage]="'custom Page text'"
      >
      </kendo-grid-messages>
    </kendo-grid>
  </div>
  <div class="loader" *ngIf="loading === true"></div>
  <!--Table des client -->
  <div fxFlex="70%" id="tableBox" class="tableContentBox" *ngIf="seeTable">
    <kendo-grid [data]="gridView"
                [selectable]="{checkboxOnly: true, mode: 'single'}"
                [resizable]="true"
                [pageSize]="pageSize"
                [skip]="skip"
                [pageable]="true"
                [style.height.px]="500"
                (selectionChange)="selectionChange($event)">
      <kendo-grid-checkbox-column class="shepherd-button-022" width="50"
                                  [headerClass]="'tableHeader'"></kendo-grid-checkbox-column>
      <kendo-grid-column field="cetatu" title="Code client" [headerClass]="'tableHeader'"></kendo-grid-column>
      <kendo-grid-column field="rscli" title="Raison sociale" [headerClass]="'tableHeader'"></kendo-grid-column>
      <kendo-grid-messages groupPanelEmpty="Drag here to group" noRecords="Aucun client trouvé">
      </kendo-grid-messages>
      <kendo-grid-messages
        [pagerItemsPerPage]="'custom items per page text'"
        [pagerItems]="''"
        [pagerOf]="'sur'"
        [pagerPage]="'custom Page text'"
      >
      </kendo-grid-messages>
    </kendo-grid>
  </div>
</div>

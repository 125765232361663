import {Component, OnInit} from '@angular/core';
import {AllClientsListItem, ClientInPegase} from "@shared/models/client-model";
import {GroupDescriptor, orderBy, SortDescriptor} from "@progress/kendo-data-query";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Shepher} from "@shared/models/shepher-model";
import {ClientService} from "@app/services/client.service";
import {ShepherdInternServiceService} from "@app/services/shepherd-intern-service.service";
import {GridDataResult, PageChangeEvent, RowClassArgs} from "@progress/kendo-angular-grid";
import {ShepherdsHelper} from "@app/helpers/shepherds.helper";
import {finalize} from "rxjs/operators";
import {CreateCompteClipForm} from "@shared/models/create-compte-clip-form-model";
import {UserHelper} from "@app/helpers/user.helper";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {DialogComponent} from "@shared/components/dialog/dialog.component";
import {Router} from "@angular/router";
import {Location} from "@angular/common";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  public gridViewConctact: any[];
  public pageSizeContact = 100;
  public skipContact = 0;
  private itemsContactAndClient: AllClientsListItem[] = [];
  public multiple = true;
  public allowUnsort = true;
  public sort: SortDescriptor[] = [{field: 'rscli', dir: 'asc'}];
  public groups: GroupDescriptor[] = [{field: 'contact'}];
  public etats: any[];
  defaultValueEtats: string = 'Tous';
  public loading: boolean;
  stepsArray: Shepher[];
  searchString: string ='';

  openFormAssociateClientToConctact: boolean = false;
  pageSizeClient = 100;
  gridViewClient: GridDataResult;
  itemsClient: any[] = [];
  newClientForm: FormGroup;
  contact: string;
  contactMail: string;
  skipClient = 0;

  constructor(
    private clientService: ClientService,
    private fb: FormBuilder,
    private shepherdInternService: ShepherdInternServiceService,
    public matDialog: MatDialog,
    private router: Router,
    public location: Location) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.getContacts();
  }

  loadHelpInline() {
    this.stepsArray = [];

    // créeation de l'array des steps
    this.stepsArray.push(new Shepher('clients', '.shepherd-button-01'
      , ["Cet onglet contient un tableau des accès clients existants."], false, true));

    this.stepsArray.push(new Shepher('creerUnAccesClient', '.shepherd-button-02'
      , ["Cet onglet permet de créer un accès client."], true, true));

    this.stepsArray.push(new Shepher('envoyerMessageClient', '.shepherd-button-03'
      , ["Cet onglet permet d'envoyer un message  qui s'affichera dans le portail CLIP d'un client."],
      true, true));
    this.stepsArray.push(new Shepher('historiqueMessagesClient', '.shepherd-button-04b'
      , ["Cet onglet contient l'historique des activations d'accès des comptes clients."],
      true, true));

    this.stepsArray.push(new Shepher('rechercheUnClient', '.shepherd-button-311'
      , ["Rechercher un contact par son nom ou nom de l'un de ses clients."], true, true));
    this.stepsArray.push(new Shepher('tableDesClients', '.shepherd-button-312'
      , ["Tableau des accès clients groupé par contact. Chaque page du tableau peut être trié par Contact," +
      " par client ou les deux à la foi. On peut filtrer sur l’état (Actif, Inactif ou Tous), filtrer sur Demat(O:oui, N:non ou Tous)"],
      true, true, false));
    //ajouter les steps dans  ShepherdHelper
    this.shepherdInternService.createTourOfSteps(this.stepsArray);

  }

  ngAfterViewInit(): void {
    ShepherdsHelper.setCurrentComponent(this);
    this.shepherdInternService.removeStep();
    if (ShepherdsHelper.getState() === true) {
      this.loadHelpInline();
    }
  }

  getContacts() {
    this.clientService.getAllClients(0, this.pageSizeContact)
      .pipe(finalize(() => {
        this.loadItemsContact();
      }))
      .subscribe(res => {
          res.forEach(client => {
            this.itemsContactAndClient.push({
              id_user: client['id_user'],
              coge: client['coge'],
              contact: client['contact'],
              login: client['login'],
              email: client['email'],
              is_signataire: client['is_signataire'],
              is_invoicing: client['type_contact'] == 'F',
              is_actif: client['is_actif'] == 1 ? 'Actif' : 'Inactif',
              dat_created: client['dat_created'],
              credentials: client['credentials'],
              compte_en_lys: client['compte_en_lys'],
              code_legalyspace: client['code_legalyspace'],
              dactiv: client['dactiv'],
              demmat: client['demmat'],
              cetatu: client['cetatu'],
              rscli: client['rscli']
            })
          })
        }
      );
  }

  private loadItemsContact(): void {
    this.gridViewConctact = this.itemsContactAndClient;
    if(this.gridViewConctact.length >0) {
      this.loading = false;
    }
    this.etats = this.itemsContactAndClient.map(item => item.is_actif).filter((value, index, self) => self.indexOf(value) === index);
    this.etats.push(this.defaultValueEtats);
  }

  public pageChangeContact(event: PageChangeEvent): void {
    this.skipContact = event.skip;
  }

  public groupChangeContact(groups: GroupDescriptor[]): void {
    this.groups = groups;
    this.loadItemsContact();
  }

  public rowCallback(context: RowClassArgs) {
    const isEven = context.index % 2 == 0;
    return {
      even: isEven,
      odd: !isEven
    };
  }

  public sortChangeContact(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadItemsContact();
  }

  onSearchContact(filter) {
    this.searchString = filter.trim();
    let clients: AllClientsListItem[] = [];
    if (this.searchString.length > 0) {
      this.loading = true;
      this.clientService.searchClientsInClip(this.searchString)
        .subscribe((res: any[]) => {
          res.forEach(client => {
            clients.push({
              id_user: client['id_user'],
              coge: client['coge'],
              contact: client['contact'],
              login: client['login'],
              email: client['email'],
              is_signataire: client['is_signataire'],
              is_invoicing: client['type_contact'] == 'F',
              is_actif: client['is_actif'] == 1 ? 'Actif' : 'Inactif',
              dat_created: client['dat_created'],
              credentials: client['credentials'],
              compte_en_lys: client['compte_en_lys'],
              code_legalyspace: client['code_legalyspace'],
              dactiv: client['dactiv'],
              demmat: client['demmat'],
              cetatu: client['cetatu'],
              rscli: client['rscli']
            })
          });
          this.loading = false;
          //allowe Kendo angular pager will reset back to the first page
          this.pageChangeContact({skip: 0, take: 0});
          this.gridViewConctact = clients;
        });
    } else {
      this.getContacts();
    }
  }

  public loadItemsClient(): void {

    this.loading = false;
    this.gridViewClient = {
      data: orderBy(this.itemsClient.slice(this.skipClient, this.skipClient + this.pageSizeClient), this.sort),
      total: this.itemsClient.length
    };
  }

  public pageChangeClient(event: PageChangeEvent): void {
    this.skipClient = event.skip;
    this.loadItemsClient();
  }

  onSearchClient(filter) {
    if (filter.trim()) {
      this.loading = true;
      this.clientService.searchClientsInPegase(filter.trim())
        .subscribe((res: ClientInPegase[]) => {
            if (res) {
              this.pageSizeClient = res.length;
              this.loading = false;
              this.gridViewClient = {
                data: orderBy(res, this.sort),
                total: res.length
              }
            }
          }
        );
    } else {
      this.loadItemsClient();
    }
  }

  openFormAssociateClientToContact(group) {
    this.contact = group.items[0]?.contact;
    this.contactMail = group.items[0]?.email;
    this.newClientForm = new FormGroup({
      is_signataire: new FormControl(true, Validators.required)
    });

    if (!this.openFormAssociateClientToConctact) {
      this.openFormAssociateClientToConctact = true;
      if (ShepherdsHelper.getState() === true) {
        //fermer un pop ouvert si un popup est ouvert avant d'ouvrir un nouveau
        this.shepherdInternService.hideStep();

        this.stepsArray.push(new Shepher('rechercheUnClientC', '.shepherd-button-searchClient'
          , ["Rechercher un client par son code ou raison social."], true, true));
        this.stepsArray.push(new Shepher('tableDesClientsC', '.shepherd-button-tabClient'
          , ["Tableau de clients PEGASE."], true, true));
        this.stepsArray.push(new Shepher('associeClientContact', '.shepherd-button-clientContact'
          , ["Cliquer pour associer le client à un contact."], true, false, true));

        this.shepherdInternService.createSteps(this.stepsArray);
        this.shepherdInternService.getShepherdService().show('clipCreateForm');
      }
    }
  }

  createCompteClip(dataItem) {
    if (this.newClientForm.valid && this.contactMail != null && dataItem.cetatu != null) {
      this.clientService.createCompteClip(new CreateCompteClipForm(UserHelper.getUser().coge, dataItem.cetatu, this.contactMail,
        this.contact, this.newClientForm.value.is_signataire), this);
    }
  }

  reloadTab() {
    this.onSearchContact(this.searchString)
  }

  onDelete(dataItem) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;// The user can't close the dialog by clicking outside its body
    dialogConfig.id = "modal-component";
    dialogConfig.height = "200px";
    dialogConfig.width = "360px";
    dialogConfig.data = {
      name: "deleteClient",
      title: "Demande de confirmation",
      description: "Etes-vous certain de vouloir supprimer ce client ?",
      actionButtonText: "Oui",
      idUser: dataItem.id_user,
      cetatu: dataItem.cetatu,
      component: this,
    }
    this.matDialog.open(DialogComponent, dialogConfig);
  }

  close() {
    this.openFormAssociateClientToConctact = false;
    this.refreshComponent();
  }

  refreshComponent() {
    this.router.navigateByUrl("tableau-de-bord/refresh", {skipLocationChange: true}).then(() => {
      this.router.navigate([decodeURI(this.location.path())]);
    })
  }
}


import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ShepherdsHelper } from '@app/helpers/shepherds.helper';
import { UserHelper } from '@app/helpers/user.helper';
import { ClientService } from '@app/services/client.service';
import { ShepherdInternServiceService } from '@app/services/shepherd-intern-service.service';
import { ClientInPegase } from '@app/shared/models/client-model';
import { Shepher } from '@app/shared/models/shepher-model';
import {GridDataResult, PageChangeEvent} from '@progress/kendo-angular-grid';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'app-send-message-to-clip-compte',
  templateUrl: './send-message-to-clip-compte.component.html',
  styleUrls: ['./send-message-to-clip-compte.component.css']
})
export class SendMessageToClipCompteComponent implements OnInit, AfterViewInit {
  public gridView: GridDataResult;
  public pageSize = 100;
  public skip = 0;
  items: any[] = [];
  public coge: string;
  public sort: SortDescriptor[] = [{ field: 'nom', dir: 'asc' }];
  seeTable: boolean = false;
  seeForm: boolean = false;
  client: ClientInPegase;
  clientName: string= '';
  messageForm: FormGroup;
  stepsArray: Shepher[];
  public loading: boolean;

  constructor(
    private clientService: ClientService,
    private shepherdInternService: ShepherdInternServiceService) { }

  ngOnInit(): void {
    this.coge = UserHelper.getUser().coge;
  }

  ngAfterViewInit(): void {
    ShepherdsHelper.setCurrentComponent(this);
    this.shepherdInternService.removeStep();
    if (ShepherdsHelper.getState() === true) {
      this.loadHelpInline();
    }
  }

  loadHelpInline() {
    this.stepsArray = [];
    //creer le  popup de la zone de recherche
    this.stepsArray.push(new Shepher('clipRechercheInPegaseMessage', '.shepherd-button-031'
      , ['Recherchez un client dans PEGASE par son code ou raison social.'], false, true));
    this.shepherdInternService.createTourOfSteps(this.stepsArray);
  }

  onSearch(filter) {
    if (filter.trim()) {
      this.loading = true;
      this.clientService.searchClientsInPegaseWithActifAccount(filter.trim())
        .subscribe((res: ClientInPegase[]) => {
          if(res) {
            this.pageSize = res.length;
            this.gridView = {
              data: orderBy(res, this.sort),
              total: res.length
            };
            this.loading = false;
          }

          if (!this.seeTable) {
            this.seeTable = true;
            if (ShepherdsHelper.getState()=== true) {
              //fermer un pop ouvert si un popup est ouvert avant d'ouvrir un nouveau
              this.shepherdInternService.hideStep();

              this.stepsArray.push(new Shepher('clipCreateTableMessage', '.shepherd-button-032',
                ["Tableau des clients dont le nom contient l'expression rechercher. Selectioner un client en cliquant sur la case à cocher afin d'ouvrir le formulaire d'envoie de message."], true, true));
              this.shepherdInternService.createSteps(this.stepsArray);
              this.shepherdInternService.getShepherdService().show('clipCreateTableMessage');
            }
          }
        }
        );
    } else {
      this.seeTable = false
    }
  }

  public selectionChange(e) {
    if (e.selectedRows[0]) {
      this.client = e.selectedRows[0].dataItem;
      this.clientName = this.client.rscli !==null? this.client.rscli:'';
      this.messageForm = new FormGroup({
        to: new FormControl({ value: this.client.cetatu, disabled: true }, Validators.required),
        start_date: new FormControl(null, Validators.required),
        end_date: new FormControl(null, Validators.required),
        subject: new FormControl(null, [Validators.required, Validators.maxLength(25)]),
        message: new FormControl(null, [Validators.required, Validators.maxLength(600)])
      });
      if (!this.seeForm) {
        this.seeForm = true;
        if (ShepherdsHelper.getState() === true) {
           //fermer un pop ouvert si un popup est ouvert avant d'ouvrir un nouveau
           this.shepherdInternService.hideStep();
           this.stepsArray.push(new Shepher('clipCreateFormMessage', '.shepherd-button-033',
             ["Ce formulaire permet d’envoyer un message qui s’affichera dans le portail CLIP d’un client. Le champs « À » est prérempli par le code client. Le champ « Sujet » ne peut contenir que 25 caractères. Le champ « Message » ne peut contenir que 600 caractères."]
             , true, false, true));
            this.shepherdInternService.createSteps(this.stepsArray);
           this.shepherdInternService.getShepherdService().show('clipCreateFormMessage');
         }
      }
    } else {
      this.seeForm = false;
    }
  }

  sendMessage() {
    if (this.messageForm.status === 'VALID') {
      this.clientService.sendMessageClip(this.messageForm.getRawValue());
      this.seeForm = false;
    }
  }

  cancel() {
    this.seeForm = false;
  }

}

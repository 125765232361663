import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { AuthenticationService } from '@app/services/authentication.service';
import { UserHelper } from '@app/helpers/user.helper';
import { User } from '@app/shared/models/user.model';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { ShepherdsHelper } from '@app/helpers/shepherds.helper';
import { ShepherdInternServiceService } from '@app/services/shepherd-intern-service.service';
import { Agency } from '@app/shared/models/agence';
import { Location} from '@angular/common';

@Component({
  selector: 'app-dashboardscreen',
  templateUrl: './dashboardscreen.component.html',
  styleUrls: ['./dashboardscreen.component.css']
})
export class DashboardscreenComponent implements OnInit, AfterViewInit {
  user: User;
  userNameUser: string;
  coge: string;
  agencies: Agency[] = [];
  navigableModule: string = 'home';
  public selectedItem: number;
  helpInLineChecked = false;
  opened = true;
  over = 'side';
  media$: Observable<MediaChange[]>;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    media: MediaObserver,
    private shepherdInternService: ShepherdInternServiceService,
    public location: Location) {

      // pour gerer le responsive de l'ecran
    this.media$ = media.asObservable();
    this.media$.subscribe((changes: MediaChange[]) => {
      if (changes[0].mqAlias === 'sm' || changes[0].mqAlias === 'xs') {
        this.opened = false;
        this.over = 'over';
      } else {
        this.opened = true;
        this.over = 'side';
      }
    });

  }

  ngOnInit(): void {
    if (UserHelper.getUser()) {
      this.user = UserHelper.getUser();
      this.coge = UserHelper.getUser().coge ? UserHelper.getUser().coge : "";
      this.userNameUser = UserHelper.getUser().nom ? UserHelper.getUser().nom : "";
      this.agencies = UserHelper.getUser().agencies;
    }
  }

  ngAfterViewInit(): void {
  }

  ngAfterViewChecked() {
    this.navigableModule = sessionStorage.getItem('module');
    this.cdRef.detectChanges();
  }

  getHome() {
    this.selectedItem = 0;
    this.router.navigate(['tableau-de-bord/accueil']);
  }

  getClient() {
    this.selectedItem = 1;
    this.router.navigate(['tableau-de-bord/clip']);
  }

  getInterimaire() {
    this.selectedItem = 2;
    this.router.navigate(['tableau-de-bord/isi']);
  }

  addNewClient() {
    this.selectedItem = 3;
    this.router.navigate(['tableau-de-bord/nouveau-acces-clip']);
  }

  addNewInterimaire() {
    this.selectedItem = 4;
    this.router.navigate(['tableau-de-bord/nouveau-acces-isi']);
  }

  sendMessageCLIP() {
    this.selectedItem = 5;
    this.router.navigate(['tableau-de-bord/message-clip']);
  }

  sendMessageISI() {
    this.selectedItem = 6;
    this.router.navigate(['tableau-de-bord/message-isi']);
  }

  messageClipHistory() {
    this.selectedItem = 7;
    this.router.navigate(['tableau-de-bord/message-clip-history']);
  }

  messageIsiHistory() {
    this.selectedItem = 8;
    this.router.navigate(['tableau-de-bord/message-isi-history']);
  }

  getContact() {
    this.selectedItem = 9;
    this.router.navigate(['tableau-de-bord/clip-contacts']);
  }

  getAd() {
    this.selectedItem = 10;
    this.router.navigate(['tableau-de-bord/ad']);
  }

  logout() {
    this.authService.logout();
  }

  /**
   * cette methode permet d'activer ou desactiver l'aide en ligne
   */
  changed() {
    ShepherdsHelper.setState(this.helpInLineChecked);
    if (this.helpInLineChecked) {
      this.shepherdInternService.enable();
    }else {
      this.shepherdInternService.disable();

    }
  }

  switchAgency() {
    UserHelper.switchAgency(this.coge);
    this.refreshComponent();
  }

  refreshComponent() {
    this.router.navigateByUrl("tableau-de-bord/refresh", { skipLocationChange: true}).then(() => {
      this.router.navigate([decodeURI(this.location.path())]);
    });
  }

  getMessagesCLIP() {
    this.selectedItem = 12;
    this.router.navigate(['tableau-de-bord/messages-clip']);
  }

  getMessagesISI () {
    this.selectedItem = 11;
    this.router.navigate(['tableau-de-bord/messages-isi']);
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShepherdsHelper } from '@app/helpers/shepherds.helper';
import { Shepher } from '@app/shared/models/shepher-model';
import { ShepherdService } from 'angular-shepherd';
import { Subject } from 'rxjs';
import Shepherd from 'shepherd.js';

@Injectable({
  providedIn: 'root'
})
export class ShepherdInternServiceService {

  public constructor(private shepherdService: ShepherdService, private route:ActivatedRoute) {
  }
  public getShepherdService() {
    return this.shepherdService;
  }

  public enable() {
    let component = ShepherdsHelper.getCurrentComponent();
    if (component !== null) {
      component.ngAfterViewInit()
    }
  }

  public disable() {
    this.removeStep();
  }

  /**
   * Supprimer tout les steps d'un tour
   */
  public removeStep() {
    this.shepherdService.tourObject?.steps.forEach((element) => {
      element.destroy();
    });
  }

  /**
   * Prend un tableau des Shephers et cree les steps
   * @param shepherArray An array of shephers
    */
  public createSteps(shepherArray: Shepher[]) {

    let steps: Array<any> = [];
    for (let entry of shepherArray) {
      //creation des bouttons back, next ou cancel de la boite de dialogue de l'aide en ligne
      let buttonsArray: any[] = [];
      //creation du bouton back
      if (entry.useBackButton) {
        buttonsArray.push({
          classes: '-secondary',
          text: 'Retour',
          type: 'back'
        });
      }
      //creation du bouton next
      if (entry.useNextButton) {
        buttonsArray.push({
          classes: '-primary',
          text: 'Suivant',
          type: 'next'
        });
      }
      //creation du bouton exit
      if (entry.useExitButton) {
        buttonsArray.push({
          classes: '-secondary',
          text: 'Quitter',
          type: 'cancel'
        });

      }
      steps.push({
        id: entry.id,
        attachTo: {
          element: entry.element,
          on: 'bottom'
        },
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            setTimeout(function () {
              window.scrollTo(0, 0);
              resolve();
            }, 500);
          });
        },
        buttons: buttonsArray,
        cancelIcon: {
          enabled: true
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        scrollTo: false,
        title: 'Visite guidée de votre portail PILOTE!',
        text: entry.text,

      });
    }
    //ajouter les steps
    this.shepherdService.addSteps(steps);

  }

  public hideStep() {
    if (this.shepherdService.isActive) {
      this.shepherdService.hide();
    }
  }

  public showStep(stepsArray: Shepher[]) {
  }

  public createTourOfSteps(stepsArray: Shepher[]) {
    //initialiser le tour
    this.shepherdService.tourObject = new Shepherd.Tour({
      defaultStepOptions: {
        classes: 'custom-class-name-1 custom-class-name-2',
        scrollTo: false,
        cancelIcon: {
          enabled: true
        }
      },
      tourName: '',
    });

    //mettre l'effet Overlay sur la boite de dialogue de l'aide en ligne
    this.shepherdService.modal = true;
    this.createSteps(stepsArray);
    //demarer le steps
    this.shepherdService.start();
  }
}

import { Component, OnInit } from '@angular/core';
import {GridDataResult, PageChangeEvent} from "@progress/kendo-angular-grid";
import {orderBy, SortDescriptor} from "@progress/kendo-data-query";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ClientService} from "@app/services/client.service";
import {Commercial} from "@shared/models/commercial";
import {Router} from "@angular/router";
import {Location} from "@angular/common";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {DialogComponent} from "@shared/components/dialog/dialog.component";

@Component({
  selector: 'app-client-order-managers',
  templateUrl: './client-order-managers.component.html',
  styleUrls: ['./client-order-managers.component.css']
})
export class ClientOrderManagersComponent implements OnInit {
  public gridView: GridDataResult;
  public pageSize = 100;
  public skip = 0;
  public sort: SortDescriptor[] = [{ field: 'nom', dir: 'asc' }];
  items: any[] = [];
  itemsIsGet: boolean = false;
  commercialForm: FormGroup;
  commercial: Commercial = new Commercial("","");
  commercials: Commercial[]= [];

  updateCommercialForm: FormGroup;
  seeFormUpdate: boolean = false;
  idCommercial: string = "";

  constructor(private clientService: ClientService,
              private router: Router,
              public location: Location,
              public matDialog: MatDialog,) {
    this.commercialForm = new FormGroup({
      commercial: new FormControl(this.commercial),
      ccomer: new FormControl(null, Validators.required),
      name: new FormControl(null, Validators.required),
      portable: new FormControl(null),
      email: new FormControl(null, [Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")])
    });
  }

  ngOnInit(): void {
    this.loadItems();
    this.clientService.getCommercials()
      .subscribe((res: any[]) => {
       res.forEach(re => {
         this.commercials.push( new Commercial(re.nom, re.ccomer));
         }
       );
      });
  }

  getItems() {
    this.clientService.getCommercialsAssociatedWithOrder()
      .subscribe(res => {
        this.items = res;
        this.itemsIsGet = true;
        this.loadItemsInGridView();
      });
  }

  loadItemsInGridView() {
    this.gridView = {
      data: orderBy(this.items.slice(this.skip, this.skip + this.pageSize), this.sort),
      total: this.items.length
    };
  }

  public loadItems(): void{
    if(this.itemsIsGet === true) {
      this.loadItemsInGridView();
    }else {
      this.getItems();
    }
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadItems();
  }

  public managersMailSelected() {
    this.commercialForm.controls['ccomer'].setValue(this.commercial.ccomer);
    this.commercialForm.controls['name'].setValue(this.commercial.name);
  }

  createManager() {
    if (this.commercialForm.valid) {
    this.clientService.createCommercialAssociatedWithOrder({
      "nom": this.commercialForm.value.name,
      "ccomer": this.commercialForm.value.ccomer,
      "email": this.commercialForm.value.email,
      "portable": this.commercialForm.value.portable
    });
    this.refreshComponent();
    }
  }

  onUpdate(dataItem) {
    this.seeFormUpdate = true;
    this.idCommercial = dataItem.id;
    this.updateCommercialForm = new FormGroup({
      email: new FormControl(null, Validators.required),
      portable: new FormControl(null, Validators.required),
    }
    );
  }

  onDelete(dataItem) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;// The user can't close the dialog by clicking outside its body
    dialogConfig.id = "modal-component";
    dialogConfig.height = "200px";
    dialogConfig.width = "360px";
    dialogConfig.data = {
      name: "deleteCommercial",
      title: "Demande de confirmation",
      description: "Etes-vous certain de vouloir supprimer ce commerciale ?",
      actionButtonText: "Oui",
      id: dataItem.id,
      component: this,
    }
    const modalDialog = this.matDialog.open(DialogComponent, dialogConfig);
  }

  cancel() {
    this.commercialForm.reset();
  }

  updateCommercial() {
    if(this.updateCommercialForm.valid) {
      this.clientService.updateCommercialAssociatedWithOrder(
        {"email":this.updateCommercialForm.value.email,
              "portable": this.updateCommercialForm.value.portable}, this.idCommercial);
      this.refreshComponent();
    }

  }

  cancelUpdate() {
    this.seeFormUpdate = false;
  }

  refreshComponent() {
    this.router.navigateByUrl("tableau-de-bord/refresh", { skipLocationChange: true}).then(() => {
      this.router.navigate([decodeURI(this.location.path())]);
    });
  }

}

<div class="mainBox" fxLayout="column" flexfill>
  <div class="searchBoxc">
    <!--searchBox-->
    <div class="searchDiv" fxLayout="row wrap" flexfill>
      <label [for]="'input'" class="k-labeltbox">Rechercher un message </label>
     <input class="k-textbox shepherd-button-chercher-msg-clip" placeholder="Effectuez la recherche d'un message" kendoTextBox (input)="onFilter($event.target.value)"/>
    </div>
  </div>
  <div class="loader" *ngIf="loading === true"></div>
  <div class="tableContentBoxc">
    <kendo-grid
      [data]="gridView"
      [selectable]="true"
      [pageSize]="pageSize"
      [skip]="skip"
      [pageable]="true"
      [style.height]="'100%'"
      [resizable]="true"
      (pageChange)="pageChange($event)"
    >
      <kendo-grid-command-column title="Action" width="50" [headerClass]="'tableHeader'" [resizable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
          <!-- Supprimer-->
          <button mat-icon-button aria-label="delete" class='red-icon' matTooltip="Supprimer" color="primary"
                  (click)="deleteMessage(dataItem)">
            <mat-icon> delete</mat-icon>
          </button>
        </ng-template>
      </kendo-grid-command-column>
      <kendo-grid-column field="nom" title="Destinataire" width="160" [headerClass]="'tableHeader'">
      </kendo-grid-column>
      <kendo-grid-column field="titre" title="Sujet" width="160" [headerClass]="'tableHeader'">
      </kendo-grid-column>
      <kendo-grid-column field="message" title="message" width="300" [headerClass]="'tableHeader'">
      </kendo-grid-column>
      <kendo-grid-column class="shepherd-button-msg-clip" field="dateAction" title="Date d'action" width="160" [headerClass]="'tableHeader'">
      </kendo-grid-column>
      <kendo-grid-column field="dateFin" title="Date de fin" width="160" [headerClass]="'tableHeader'">
      </kendo-grid-column>
      <kendo-grid-column field="date" title="Date d'envoi" width="160" [headerClass]="'tableHeader'">
      </kendo-grid-column>
      <kendo-grid-messages groupPanelEmpty="Drag here to group" noRecords="Aucun message trouvé">
      </kendo-grid-messages>
      <kendo-grid-messages
        [pagerItemsPerPage]="'custom items per page text'"
        [pagerItems]="''"
        [pagerOf]="'sur'"
        [pagerPage]="'custom Page text'"
      >
      </kendo-grid-messages>
    </kendo-grid>
  </div>
</div>


<div class="mainBox" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="start center" class="mt-15" fxLayoutGap="100px" height="100%" width="100%">
    <div class="center shepherd-button-1 select-app" fxflex="50%">
      <a [ngClass]="{'isDisabled': !clipModule}" (click)="loadCLIPModule()">
        <img src="assets/images/Clip_400px.png" alt="Logo CLIP" class="responsive">
      </a>
    </div>
    <div class="center shepherd-button-2 select-app" fxflex="50%">
      <a [ngClass]="{'isDisabled': !isiModule}" (click)="loadISIModule()">
        <img src="assets/images/Logo_ISI_400px.png" alt="Logo ISI" class="responsive">
      </a>
    </div>
    <div class="center shepherd-button-2 select-app" fxflex="35%" *ngIf="false">
      <a (click)="loadAdModule()">
        <img src="assets/images/svg/hiring.svg" alt="Logo Ad" class="responsive">
      </a>
    </div>
  </div>
  <div class="logoPegaseBox parameters" fxLayout="column" fxLayoutAlign="start start" fxflex="30%">
    <a (click)="loadClientOrderManagersPModule()">
      <img src="assets/images/pegase_Image.png" alt="Logo PEGASE" class="responsive redimension shepherd-button-pegase-mail">
    </a>
    <span class="reglageImgTitle">Paramètres</span>
  </div>
</div>

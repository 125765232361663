import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FlexLayoutModule} from "@angular/flex-layout";
import {MaterialModule} from "@shared/material.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import { FooterComponent } from './components/footer/footer.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { SharedLayoutComponent } from './components/shared-layout/shared-layout.component';
import { HeaderComponent } from './components/header/header.component';

const allSharedComponents= [FormsModule,
                           FlexLayoutModule,
                           HttpClientModule,
                           ReactiveFormsModule,
                           MaterialModule,
                           ]
@NgModule({
  declarations: [FooterComponent, DialogComponent, SharedLayoutComponent, HeaderComponent],
  imports: [
    CommonModule,
    allSharedComponents,
  ],
  exports: [
    FooterComponent,
    DialogComponent,
    SharedLayoutComponent,
    allSharedComponents,
    HeaderComponent
  ],
  providers: [],
  entryComponents: [
    DialogComponent,
    SharedLayoutComponent
  ]
})
export class SharedModule { }

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {WEBSERVICE_ADDR} from '@shared/constants';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserHelper} from '@app/helpers/user.helper';
import {Router} from '@angular/router';
import {Interimaire} from "@shared/models/interimaire-model";
import {AdModel} from "@shared/models/ad-model";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AdService {
  private END_POINT: string = 'ad';
  contentTypeUrlencoded = 'application/x-www-form-urlencoded';
  contentTypeJson = 'application/json';

  constructor(private http: HttpClient, private snackBar: MatSnackBar, private router: Router) {
  }

  getHeader(contentType: string) {
    return new HttpHeaders({
      'Content-Type': contentType,
      'Authorization': `Bearer ${UserHelper.getUser().token}`
    });
  }

  getAds(): Observable<AdModel[]>  {
    const headers = this.getHeader(this.contentTypeJson);
    const url = `${WEBSERVICE_ADDR}${this.END_POINT}`;
    return this.http.get<AdModel[]>(url, { headers });
  }

  getAd(id:string) {
    const headers = this.getHeader(this.contentTypeJson);
    const url = `${WEBSERVICE_ADDR}${this.END_POINT}/${id}`;
    return this.http.get<AdModel>(url, { headers });
  }

  updateAd(id:string, data: object) {
    const headers = this.getHeader(this.contentTypeJson);
    const url = `${WEBSERVICE_ADDR}${this.END_POINT}/${id}`;

    this.http.put(url, data, { headers: headers, observe: "response" })
      .subscribe(response => {
          if (response.status === 204) {
            this.snackBar.open("Annonce modifié avec succès", null, { duration: 4000 });
            this.router.navigate(['tableau-de-bord/ad'])
          }
        },
        error => {
          console.log(error);
        }
      );
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';

import { UpdateComponent } from '@components/dashboardscreen/ad/update/update.component';
import { AdComponent } from '@components/dashboardscreen/ad/home/ad.component.ts';
import { ClientsComponent } from './components/dashboardscreen/clip/clients/clients.component';
import { InterimairesComponent } from './components/dashboardscreen/isi/interimaires/interimaires.component';
import { SharedModule } from './shared/shared.module';
import {  ErrorInterceptor } from '@app/helpers/error.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HomeComponent } from '@app/components/homescreen/home/home.component';
import { SigninComponent } from '@app/components/homescreen/signin/signin.component';
import { AuthComponent } from '@app/components/homescreen/auth/auth.component';
import { DashboardscreenComponent } from './components/dashboardscreen/dashboardscreen/dashboardscreen.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { AddNewInterimaireComponent } from './components/dashboardscreen/isi/add-new-interimaire/add-new-interimaire.component';
import { AddNewClientComponent } from './components/dashboardscreen/clip/add-new-client/add-new-client.component';
import { DropdownlistFilterComponent } from './components/dashboardscreen/dropdownlist-filter/dropdownlist-filter.component';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { SendMessageToClipCompteComponent } from './components/dashboardscreen/clip/send-message-to-clip-compte/send-message-to-clip-compte.component';
import { SendMessageToIsiCompteComponent } from './components/dashboardscreen/isi/send-message-to-isi-compte/send-message-to-isi-compte.component';
import { MessageClipHistoryComponent } from './components/dashboardscreen/clip/message-clip-history/message-clip-history.component';
import { MessageIsiHistoryComponent } from './components/dashboardscreen/isi/message-isi-history/message-isi-history.component';
import { RefreshComponent } from './components/refresh/refresh.component';
import { ClientOrderManagersComponent } from './components/dashboardscreen/clip/client-order-managers/client-order-managers.component';
import { ContactComponent } from './components/dashboardscreen/clip/contact/contact.component';
import { MessagesClipComponent } from './components/dashboardscreen/clip/messages-clip/messages-clip.component';
import { MessagesIsiComponent } from './components/dashboardscreen/isi/messages-isi/messages-isi.component';




@NgModule({
  declarations: [
    AppComponent,
    AdComponent,
    ClientsComponent,
    InterimairesComponent,
    HomeComponent,
    UpdateComponent,
    AuthComponent,
    SigninComponent,
    DashboardscreenComponent,
    AddNewInterimaireComponent,
    AddNewClientComponent,
    DropdownlistFilterComponent,
    SendMessageToClipCompteComponent,
    SendMessageToIsiCompteComponent,
    MessageClipHistoryComponent,
    MessageIsiHistoryComponent,
    RefreshComponent,
    ClientOrderManagersComponent,
    ContactComponent,
    MessagesClipComponent,
    MessagesIsiComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    InputsModule,
    GridModule,
    FormsModule,
    ReactiveFormsModule,
    DropDownListModule
  ],
  exports: [],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  entryComponents:[
    AdComponent,
    ClientsComponent,
    InterimairesComponent,
    AddNewClientComponent,
    AddNewInterimaireComponent,
    HomeComponent,
    SendMessageToClipCompteComponent,
    SendMessageToIsiCompteComponent,
  ]
})
export class AppModule { }

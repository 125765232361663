import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WEBSERVICE_ADDR } from '@shared/constants';
import { map } from 'rxjs/operators';
import { Client } from '@app/shared/models/client-model'
import { Observable, of } from 'rxjs';
import { UpdatePersonModel } from '@app/shared/models/person-model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CreateCompteClipForm } from '@app/shared/models/create-compte-clip-form-model';
import { UserHelper } from '@app/helpers/user.helper';
import { Router } from '@angular/router';
import {ClientOrderManagersComponent} from '@components/dashboardscreen/clip/client-order-managers/client-order-managers.component';
import {ContactComponent} from '@components/dashboardscreen/clip/contact/contact.component';
import {MessagesClipComponent} from '@components/dashboardscreen/clip/messages-clip/messages-clip.component';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  private END_POINT: string = 'clip/';
  contentTypeUrlencoded = 'application/x-www-form-urlencoded';
  contentTypeJson = 'application/json';

  constructor(private http: HttpClient, private snackBar: MatSnackBar, private router :Router) { }

  getHeader(contentType: string) {
    return new HttpHeaders({
      'Content-Type': contentType,
      'Authorization': `Bearer ${UserHelper.getUser().token}`
    });
  }
  getCommercials() {
    const headers = this.getHeader(this.contentTypeUrlencoded);
    const options = { headers };
    const url = WEBSERVICE_ADDR + 'commercial';

    return this.http.get<any[]>(url, options);
  }

  getCommercialsAssociatedWithOrder() {
    const headers = this.getHeader(this.contentTypeUrlencoded);
    const options = { headers };

    const url = WEBSERVICE_ADDR + 'contacts';

    return this.http.get<any[]>(url, options);
  }

  createCommercialAssociatedWithOrder(data: Object) {
    const headers = this.getHeader(this.contentTypeJson);
    const body = JSON.stringify(data);
    const url = WEBSERVICE_ADDR + 'contacts';
    this.http.post(url, body, { headers: headers, observe: "response" })
      .subscribe(response => {
          if (response.status === 201) {
            this.snackBar.open("Commerciale créé avec succès", null, { duration: 4000 });
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  updateCommercialAssociatedWithOrder(data: Object, id: string) {
    const headers = this.getHeader(this.contentTypeJson);
    const body = JSON.stringify(data);
    const url = WEBSERVICE_ADDR + 'contacts' + '/'+ id;
    this.http.put(url, body, { headers: headers, observe: "response" })
      .subscribe(response => {
          if (response.status === 201) {
            this.snackBar.open("Ce commerciale a été modifié", null, { duration: 4000 });
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  deleteCommercialAssociatedWithOrder(id: string, component: ClientOrderManagersComponent) {
    const headers = this.getHeader(this.contentTypeJson);
    const url = WEBSERVICE_ADDR + 'contacts' + '/'+id;
    this.http.delete(url, { headers: headers, observe: "response" })
      .subscribe(response => {
          if (response.status === 204) {
            this.snackBar.open("Ce commerciale a été supprimé", null, { duration: 4000 });
            if(component != null) {
              component.refreshComponent();
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  getContactClient(cetatu: string) {
    const headers = this.getHeader(this.contentTypeUrlencoded);
    const options = { headers };
    const url = WEBSERVICE_ADDR + 'client/search/contact/' + cetatu;

    return this.http.get<Client[]>(url, options);
  }

  getAllClients(offset: number, limit: number): Observable<Client[]> {
    const headers = this.getHeader(this.contentTypeUrlencoded);
    const options = { headers };
    const url = WEBSERVICE_ADDR + this.END_POINT + 'get-all/' + UserHelper.getUser().coge;
    return this.http.get<Client[]>(url, options);
  }

  getAllClientsPaginated(offset: number, limit: number) {
    const headers = this.getHeader(this.contentTypeUrlencoded);
    const options = { headers };
    const url = WEBSERVICE_ADDR + this.END_POINT + 'get-all-paginated/' + UserHelper.getUser().coge + '/' + offset
                + '/' + limit;

    return this.http.get(url, options)
      .pipe(
        map((response: {
          length: number,
          clients: any[]
        }) => {
          return response;
        })
      );
  }

  searchClientsInClip(searchString: string): Observable<Client[]> {
    const headers = this.getHeader(this.contentTypeUrlencoded);
    const options = { headers };
    const url = WEBSERVICE_ADDR + this.END_POINT + 'search/' + UserHelper.getUser().coge + '/' + searchString;

    if (!searchString.trim()) {
      return of([]);
    }
    return this.http.get<Client[]>(url, options);

  }

  searchClientsInPegase(searchString: string): Observable<Client[]> {
    const headers = this.getHeader(this.contentTypeUrlencoded);
    const options = { headers };
    const url = WEBSERVICE_ADDR + 'client/search/' + UserHelper.getUser().coge + '/' + searchString;

    return this.http.get<Client[]>(url, options);
  }

  searchClientsInPegaseWithActifAccount(searchString: string): Observable<Client[]> {
    const headers = this.getHeader(this.contentTypeUrlencoded);
    const options = { headers };
    const url = WEBSERVICE_ADDR + 'client/search/' + UserHelper.getUser().coge + '/' + searchString +'?with_access=true';

    return this.http.get<Client[]>(url, options);
  }

  renvoiMailDactivationt(idUser: string) {
    const headers = this.getHeader(this.contentTypeJson);
    const body = JSON.stringify({ "id_user": idUser });
    const url = WEBSERVICE_ADDR + this.END_POINT + 'reinit'

    return this.http.put(url, body, { headers: headers, observe: "response" })
      .subscribe(response => {
        if (response.status === 204) {
          this.snackBar.open('Mail de réinitialisé envoyé', null, { duration: 3000 });
        }
      },
        error => {
         console.log(error);
        }
      );
  }

  getMails(page: number, recipients: string) {
    const headers = this.getHeader(this.contentTypeJson);
    const url = `${WEBSERVICE_ADDR}${this.END_POINT}emails?page=${page}&recipients=${recipients}&order[id]=DESC`;

    return this.http.get(url,{headers: headers, observe:"response"});
  }

  setDemat(cetatu: string, statut_demat: string) {
    const headers = this.getHeader(this.contentTypeJson);
    const body = JSON.stringify({ "coge": UserHelper.getUser().coge, "cetatu": cetatu, "statut_demat": statut_demat });
    const url = WEBSERVICE_ADDR + '/client/set-demat';
    return this.http.put(url, body, { headers: headers, observe: "response" })
      .subscribe(response => {
        if (response.status === 204) {
          this.snackBar.open('Mise à jour avec succès', null, { duration: 3000 });
          setTimeout(() => {
            this.router.navigate(['tableau-de-bord/clip']);}, 0);
        }
      },
        error => {
         console.log(error);
        }
      );
  }

  update(data: UpdatePersonModel) {
    const headers = this.getHeader(this.contentTypeJson);
    const body = JSON.stringify(data);
    const url = WEBSERVICE_ADDR + this.END_POINT + 'update';

    return this.http.put(url, body, { headers: headers, observe: "response" })
      .subscribe(response => {
        if (response.status === 204) {
          this.snackBar.open('Mise à jour avec succès', null, { duration: 3000 });
          setTimeout(() => {
            this.router.navigate(['tableau-de-bord/clip']); }, 0);
        }
      },
        error => {
         console.log(error);
        }
      );
  }

  createCompteClip(data: CreateCompteClipForm, component: any) {
    const headers = this.getHeader(this.contentTypeJson);
    const body = JSON.stringify(data);
    const url = WEBSERVICE_ADDR + this.END_POINT + 'create';
    this.http.post(url, body, { headers: headers, observe: "response" })
      .subscribe(response => {
        if (response.status === 201) {
          this.snackBar.open("Compte Clip créé avec succès", null, { duration: 4000 });
         if(component instanceof ContactComponent) {
            component.reloadTab();
          }
        }
      },
        error => {
          console.log(error);
        }
      );
  }

  deleteClient(id_user: number, cetatu: string, component : any) {
    const headers = this.getHeader(this.contentTypeJson);
    const body = JSON.stringify({ "id_user":  id_user, "cetatu": cetatu});
    const options = { headers, body };
    const url = WEBSERVICE_ADDR + this.END_POINT + 'delete';
    this.http.delete(url, options)
      .subscribe(response => {
        this.snackBar.open('Ce compte a été suprimé', null, { duration: 3000 });
        if(component!=null) {
          setTimeout(() => {
            component.refreshComponent(); }, 0);
        }
      },
        error => {
          console.log(error);
        }
      );
  }

  sendMessageClip(data: object) {
    const headers = this.getHeader(this.contentTypeJson);
    const url = `${WEBSERVICE_ADDR}/client/send-message`;

    this.http.post(url, data, { headers: headers, observe: "response" })
      .subscribe(response => {
          if (response.status === 201) {
            this.snackBar.open("Message envoyé avec succès", null, { duration: 4000 });
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  openUserCompteInAdminMode(idUser: number){
    const headers = this.getHeader(this.contentTypeUrlencoded);
    const options = { headers };
    const url = `${WEBSERVICE_ADDR}${this.END_POINT}user-token/${idUser}`;

    return this.http.get<any>(url, options);
  }
  // tslint:disable-next-line:typedef
  getMessages() {
    const headers = this.getHeader(this.contentTypeUrlencoded);
    const options = { headers };
    const url = WEBSERVICE_ADDR + 'messages/client';

    return this.http.get<[any]>(url, options);
  }
  // tslint:disable-next-line:typedef
  deleteMessage(idMessage: number, component: MessagesClipComponent) {
    const headers = this.getHeader(this.contentTypeJson);
    const options = { headers };
    const url = WEBSERVICE_ADDR + 'messages/' + idMessage;
    this.http.delete(url, options)
      .subscribe(response => {
          this.snackBar.open('Ce message a été suprimé', null, { duration: 3000 });
          setTimeout(() => {
            component.refreshComponent(); }, 0);
        },
        error => {
          console.log(error);
        }
      );
  }

}

<div class="mainBox" fxLayout="column" flexfill>
  <div class="tableContentBoxc">
  <kendo-grid
  [data]="gridView"
  [pageSize]="pageSize"
  [skip]="skip"
  [pageable]="true"
  [style.height]="'100%'"
  [resizable]="true"
  (pageChange)="pageChange($event)"
  >
    <ng-template kendoGridToolbarTemplate>
     <input class="shepherd-button-041" placeholder="Recherchez une annonce" kendoTextBox (input)="onFilter($event.target.value)"/>
     </ng-template>
    <kendo-grid-command-column title="Action" width="50" [headerClass]="'tableHeader'" [resizable]="false">
      <ng-template kendoGridCellTemplate let-dataItem>
        <!--Modifier annonce-->
        <button mat-icon-button aria-label="update" class='violet-icon' matTooltip="Mettre à jour"
                (click)="openFormUpdate(dataItem.id_annonce)">
          <mat-icon>edit</mat-icon>
        </button>

      </ng-template>
    </kendo-grid-command-column>
    <kendo-grid-column  field="id_annonce" title="ID" width="160" [headerClass]="'tableHeader'">
    </kendo-grid-column>
    <kendo-grid-column  field="reference" title="Référence" width="160" [headerClass]="'tableHeader'">
    </kendo-grid-column>
    <kendo-grid-column field="titre" title="Titre" width="160" [headerClass]="'tableHeader'">
    </kendo-grid-column>
    <kendo-grid-column field="date_creation" title="Date" width="160" [headerClass]="'tableHeader'">
    </kendo-grid-column>
    <kendo-grid-column class="shepherd-button-112" field="actif" title="État" width="90" [headerClass]="'tableHeader'"
                       [sortable]="false" [resizable]="false">
    </kendo-grid-column>
<!--    <kendo-grid-column field="sent" title="Etat" width="160" [headerClass]="'tableHeader'" [resizable]="false">-->
<!--    </kendo-grid-column>-->
<!--    <kendo-grid-column field="createdAt" title="Heure d'envoi" width="160" [headerClass]="'tableHeader'" [resizable]="false">-->
<!--    </kendo-grid-column>-->
<!--    <kendo-grid-column field="updatedAt" title="Heure de mise à jour" width="160" [headerClass]="'tableHeader'" [resizable]="false">-->
<!--    </kendo-grid-column>-->
<!--    <kendo-grid-column field="errorMessage" title="Message d'erreur" width="160" [headerClass]="'tableHeader'"></kendo-grid-column>-->
    <kendo-grid-messages groupPanelEmpty="Drag here to group" noRecords="Aucun message trouvé">
    </kendo-grid-messages>
    <kendo-grid-messages
      [pagerItemsPerPage]="'custom items per page text'"
      [pagerItems]="''"
      [pagerOf]="'sur'"
      [pagerPage]="'custom Page text'"
    >
    </kendo-grid-messages>
  </kendo-grid>
  </div>
</div>




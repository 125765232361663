<div class="mainBox" fxLayout="column" flexfill>
  <div class="searchBoxc">
    <div class="searchDiv" fxLayout="row wrap" flexfill>
        <label [for]="'input'">Rechercher un intérimaire :</label>
        <input class="k-textbox shepherd-button-111" (input)="onSearch($event.target.value)"
          placeholder="Effectuez la recherche d'un intérimaire par son nom" />
      </div>
    <!--DematBox-->
    <mat-card class="marginCart" *ngIf="seeFormDemat === true">
        <form [formGroup]="dematForm"
          (ngSubmit)="demat()">
          <span class="title">Dématérialiser l'intemerimaire </span><span>{{interimaireName}} </span>
          <div class="containDiv" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column">
            <div class="containLeftDiv" fxLayout="row" fxLayoutGap="20px">
              <mat-radio-group formControlName="statut_demat">
                <mat-radio-button value="O"> Oui </mat-radio-button>
                <mat-radio-button value="N"> Non </mat-radio-button>
                <mat-radio-button value="F"> Démat et édition</mat-radio-button>
              </mat-radio-group>
              <mat-error>
            <span * ngIf="! regiForm.get ('Gender'). valid && regiForm.get ('Gender'). touched"> Veuillez sélectionner
              une
              valeur !!! </span>
              </mat-error>
            </div>
            <div class="containRigthDiv" fxLayout="row" fxLayoutAlign="end start" fxLayoutAlign.xs="center start"
                 fxLayoutAlign.sm="center start" fxLayoutGap="20px">
              <button mat-button type="submit" [disabled]="!dematForm.valid">Valider</button>
              <button mat-button (click)="cancelDemat()">Annuler</button>
            </div>
          </div>
        </form>
    </mat-card>
    <!--updateBox-->
    <mat-card class="marginCart"  *ngIf="seeFormUpdate == true">
        <form [formGroup]="updateForm" id="update-form"
          (ngSubmit)="update()">
          <span class="title"> Mettre à jour le contact : </span><span> {{interimaireName}}</span>
          <div class="containDiv" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column">
            <div class="containLeftDiv" fxLayout="row" fxLayoutGap="20px">
              <mat-form-field appearance="fill" *ngIf="updateLogin">
                <mat-label>Identifiant</mat-label>
                <input matInput  name="login"  formControlName="login" #login required>
                <mat-error>Champ obligatoire</mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill" *ngIf="updateEmail">
                <mat-label>Email</mat-label>
                <input matInput name="email" formControlName="email" #email required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
                <mat-error *ngIf="updateForm.controls.email.hasError('required')">Champ obligatoire</mat-error>
                <mat-error *ngIf="updateForm.controls.email.hasError('pattern')">Email invalide</mat-error>
              </mat-form-field>
            </div>
            <div class="containRigthDiv" fxLayout="row" fxLayoutAlign="end start" fxLayoutAlign.xs="center start"
                 fxLayoutAlign.sm="center start" fxLayoutGap="20px">
              <button mat-button type="submit" class="valider" >Valider</button>
              <button mat-button (click)="cancelUpdate()"> Annuler</button>
            </div>
          </div>

        </form>
    </mat-card>
  </div>
  <div class="loader" *ngIf="loading === true"></div>
  <div class="tableContentBoxc">
    <kendo-grid [kendoGridBinding]="gridView"
                [filterable]="true"
                [pageSize]="pageSize"
                [skip]="skip"
                [resizable]="true"
                [pageable]="true"
                [sortable]="{ allowUnsort: allowUnsort, mode: 'multiple'}"
                [selectable]="{ checkboxOnly: false, mode: 'simple' }"
                [sort]="sort"
                (sortChange)="sortChange($event)"
                (pageChange)="pageChange($event)"
                [style.height]="'100%'"
                [rowClass]="rowCallback">
      <kendo-grid-command-column title="Action"  width="210" [headerClass]="'tableHeader'" [resizable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
          <!--Demat-->
          <button mat-icon-button color="primary" aria-label="démat" matTooltip="Dématerialisation"
            (click)="openFormDemat(dataItem)"  [disabled]="(dataItem.is_actif==='Inactif')">
            <mat-icon>devices</mat-icon>
          </button>
          <!--Modifier un interimaire-->
          <button mat-icon-button aria-label="Misse à jour" class='violet-icon'matTooltip="Mettre à jour l'intérimaire"
            (click)="openFormUpdate(dataItem)">
            <mat-icon>edit</mat-icon>
          </button>
          <!--Renvoyer le mail d'activation-->
          <button mat-icon-button aria-label="renvoieMailActivation" matTooltip="Renvoyer l'email d'activation"
            (click)="onRenvoiMailActivationt(dataItem)"
            [disabled]="(dataItem.is_actif==='Actif') || (dataItem.is_actif==='Inactif' && dataItem.code_legalyspace)">
            <mat-icon>rotate_left</mat-icon>
          </button>
          <!--Cnx Tablette-->
          <button mat-icon-button aria-label="ouvrir Tablette" matTooltip="Ouvrir tablette" color="primary"
            (click)="onOpenTablette(dataItem)">
            <mat-icon>mobile_friendly</mat-icon>
          </button>
          <!-- Supprimer   -->
          <button mat-icon-button aria-label="delete" class='red-icon' matTooltip="Supprimer" color="primary"
            (click)="onDelete(dataItem)"
            [disabled]="(dataItem.is_actif==='Actif') || (dataItem.is_actif==='Inactif' && dataItem.code_legalyspace)">
            <mat-icon> delete</mat-icon>
          </button>
          <button mat-icon-button aria-label="Ouvrir ce compte ISI"  class='vert-icon'
                  matTooltip="Ouvrir ce compte ISI en mode admninistrateur" color="primary"
                  (click)="onOpenUserCompteInAdminMode(dataItem)"
                  [disabled]="(dataItem.is_actif==='Inactif')">
            <mat-icon>settings</mat-icon>
          </button>
        </ng-template>
      </kendo-grid-command-column>
      <kendo-grid-column title=""  width="50" [headerClass]="'tableHeader'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <img *ngIf="dataItem.is_signataire === 0" src="assets/images/svg/visibility_black_24dp.svg"
               class="responsive logo imageSignature"
               alt="icon signature electronic" matTooltip="Pas de droit à la signature">
          <img *ngIf="dataItem.is_signataire === 1" src="assets/images/signature1.png" class="logo imageSignature"
               alt="icon signature electronic" matTooltip="Droit à la signature">
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="contact" title="Nom" [filterable]="false" width="250" [headerClass]="'tableHeader'">
      </kendo-grid-column>
      <kendo-grid-column title=""  width="80" [headerClass]="'tableHeader'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <img *ngIf="dataItem.code_legalyspace !== null" src="assets/images/signature3.png" class="responsive logo"
               alt="icon signature electronic" matTooltip="Possede une signature">
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="nsala" title="№ intérimaire" [filterable]="false" width="140"
                         [headerClass]="'tableHeader'"
                         [resizable]="false" [sortable]="false">
      </kendo-grid-column>
      <kendo-grid-column field="id_user" title="Code utilisateur" [filterable]="false" width="130"
                         [headerClass]="'tableHeader'"
                         [resizable]="false">
      </kendo-grid-column>
      <kendo-grid-column field="login" title="Identifiant" [filterable]="false" width="100" [headerClass]="'tableHeader'"
        [sortable]="false">
      </kendo-grid-column>
      <kendo-grid-column field="email" title="Email" [filterable]="false" width="200" [headerClass]="'tableHeader'"
        [sortable]="false">
      </kendo-grid-column>
      <kendo-grid-column class="shepherd-button-112" field="is_actif" title="État" width="90" [headerClass]="'tableHeader'"
                         [sortable]="false" [resizable]="false">
        <ng-template kendoGridFilterCellTemplate let-filter>
          <app-dropdownlist-filter [filter]="filter" [data]="etats" textField="is_actif" valueField="is_actif">
          </app-dropdownlist-filter>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="dat_created" title="Date" [filterable]="false" width="150" [headerClass]="'tableHeader'"
                         [sortable]="false" [resizable]="false">
      </kendo-grid-column>
      <kendo-grid-column field="demmat" title="Démat" [filterable]="true" width="100" [headerClass]="'tableHeader'"
                         [resizable]="false">
        <ng-template kendoGridFilterCellTemplate let-filter>
          <app-dropdownlist-filter [filter]="filter" [data]="demats" textField="demmat" valueField="demmat">
          </app-dropdownlist-filter>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="code_legalyspace" title="LegalySpace" [filterable]="false" width="110"
                         [headerClass]="'tableHeader'"[sortable]="false" [resizable]="false">
      </kendo-grid-column>
      <kendo-grid-messages groupPanelEmpty="Drag here to group" noRecords="Aucun intérimaire trouvé">
      </kendo-grid-messages>
      <kendo-grid-messages
        [pagerItemsPerPage]="'custom items per page text'"
        [pagerItems]="''"
        [pagerOf]="'sur'"
        [pagerPage]="'custom Page text'"
      >
      </kendo-grid-messages>
    </kendo-grid>
  </div>
</div>
